const factory = {
  path: '/factory',
  name:'factory',
  component: () => import('@/views/factory/index.vue'),
  children:[
    // {
    //   path: 'contracts',
    //   name: 'contracts',
    //   component: () => import('@/views/factory/contracts.vue'),
    //   children: [
    //     {
    //       path: '',
    //       name: 'contractDeploy',
    //       component: () => import('@/views/factory/contracts/contractDeploy.vue')
    //     },
    //     {
    //       path: ':chain/:address',
    //       name: 'contractInfo',
    //       component: () => import('@/views/factory/contracts/contractInfo.vue')
    //     }
    //   ]
    // },
    // {
    //   path: 'contracts/:chain/:contract',
    //   name: 'contractInfo',
    //   component: () => import('@/views/factory/contractInfo.vue')
    // },
    {
      path: '',
      component: () => import('@/views/factory/toolbox.vue'),
      children: [
        {
          path: '',
          redirect: 'synthesize'
        },
        {
          path: 'synthesize',
          name: 'synthesize',
          component: () => import('@/views/factory/toolBox/synthesize.vue')
        },
        {
          path: 'decompose',
          name: 'decompose',
          component: () => import('@/views/factory/toolBox/decompose.vue')
        }
      ]
    },
    // {
    //   path: 'nft-synthesize',
    //   name: 'nftSynthesize',
    //   component: () => import('@/views/factory/nftSynthesize.vue')
    // },
    {
      path: 'dcnft-synthesize',
      name: 'dcnftSynthesize',
      component: () => import('@/views/factory/dcnftSynthesize.vue')
    },

    // {
    //   path: 'fastpacking',
    //   name: 'fastpacking',
    //   component: () => import('@/views/factory/fastpacking.vue')
    // },
    // {
    //   path: 'result',
    //   name: 'synthesisResult',
    //   component: () => import('@/views/factory/synthesisResult.vue')
    // }
  ]
}

export default factory