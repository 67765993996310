//分红
const earn = [
  { 
    path: '/faq',
    name:'faq',
    component: () => import('@/views/docs/faq/answer.vue'),
  },
  // { 
  //   path: '/faq/answer/:id',
  //   name:'answer',
  //   component: () => import('@/views/docs/faq/answer.vue'),
  // },
  
]

export default earn

