let en =   {
    title:"Frequently Asked Questions",
    HelpYouTitle:"Frequently Asked Questions",
    HelpYouTitleDes:"Browse Help Topics",
    subTitle:"",
    up:"up",
    down:"down",
    mobileMenu:"Category",
    AllCategories:"All categories",
    AnswerTitleDes:"We will constantly update the FAQ list from user feedback.",
    CategoryTitleOne:"About MSAP",
    CategoryTitleTwo:"Synthesis operation questions",
    CategoryTitleThree:"Synthesis result questions",
    OneCategoryFirstQuestion:"What is MSAP?",
    OneCategoryFirstAnswer:"MSAP is a crypto asset synthesis platform that provides users with a new way of asset management, transfer and trading. Users can flexibly combine any original digital assets in their hands into an NFT, or decompose the synthesized NFT to get the original assets.",
    OneCategorySecondQuestion:"Why do crypto assets need to be combined?",
    OneCategorySecondAnswer:"The current NFTs and the entire DeFi market lack liquidity and asset diversity, resulting in stagnation of innovation, reduced trading activity, and devaluation of crypto assets held by users. MASP creates unlimited new assets and liquidity by allowing crypto assets to be freely synthesized, creating new trading markets, and activating the potential value of crypto assets.",
    OneCategoryThirdQuestion:"What are the advantages of trading through the MSAP market?",
    OneCategoryThirdAnswer:"MSAP’s innovative trading market can improve the liquidity of the crypto asset market and increase the efficiency of users’ assets. Through the asset diversity brought by MSAP, the market liquidity and trading activity can be effectively activated, which is more conducive to users to obtain more benefits in the NFT and DeFi trading markets more efficiently.",
    OneCategoryFourthQuestion:"How does MSAP help users benefit?",
    OneCategoryFourthAnswer:"MSAP creates diversified liquidity, new trading markets and structures by allowing crypto assets to be synthesized, boosting asset trading efficiency with lower slippage, and helping users discover and seize more trading opportunities.",
    TwoCategoryFirstQuestion:"What assets can MSAP protocol use as underlying assets for synthesis?",
    TwoCategoryFirstAnswer:"MSAP protocol will eventually support any asset with transfer function for synthesis. Currently, the platform has opened the synthesis capabilities of ERC 721, ERC 1155 and ERC 20 assets.",
    TwoCategorySecondQuestion:"What asset is obtained by synthesis?",
    TwoCategorySecondAnswer:"The asset obtained by synthesis is fully compatible with ERC 721 or ERC 1155 assets, which we named as DC-NFT. Currently, the platform has opened the synthesis of underlying assets into ERC 721 standard DC-NFT.",
    TwoCategoryThirdQuestion:"Is there a limit to the number of underlying assets that can be synthesized?",
    TwoCategoryThirdAnswer:"The number of underlying assets is limited, and this limit comes from the gas cap of the block. At the current stage, in order to better serve more users, we use the contract plus token id of the underlying asset as the asset type identifier, and limit each DC-NFT to contain up to 5 different types of assets.",
    TwoCategoryFourthQuestion:"After synthesis, do these underlying assets still belong to me?",
    TwoCategoryFourthAnswer:"After the synthetic transaction is completed, the underlying assets still belong to you, because after the underlying assets are synthesized, you will get the DC-NFT that represents these underlying assets. The underlying assets are fully represented by their synthesized DC-NFT, and the owner of the DC-NFT is the owner of the underlying assets represented by the DC-NFT.",
    TwoCategoryFifthQuestion:"Can the synthesized DC-NFT be transferred to other addresses?",
    TwoCategoryFifthAnswer:"Yes, it can be transferred, because the synthesized DC-NFT is compatible with ERC 721 or ERC 1155 standards, and can be freely transferred. However, it should be noted that whoever holds the DC-NFT has the right to take back the underlying assets it represents.",
    TwoCategorySixthQuestion:"Can the synthesized DC-NFT assets be traded on other platforms?",
    TwoCategorySixthAnswer:"Yes, they can be traded on other platforms. DC-NFT is fully compatible with ERC 721 or ERC 1155 standards, and can participate in various NFT trading platforms or related projects without any restrictions.",
    TwoCategorySeventhQuestion:"Who can decompose the DC-NFT? Who owns the underlying assets after the decompose?",
    TwoCategorySeventhAnswer:"The owner of the DC-NFT can decompose the DC-NFT, and after the decompose, the DC-NFT will be destroyed, and the underlying assets it represents will be transferred to the wallet of the DC-NFT owner.",
    TwoCategoryEighthQuestion:"Can MSAP’s contract be used as an account abstraction wallet?",
    TwoCategoryEighthAnswer:"Yes. One of the important directions of MSAP is to be a specific implementation of account abstraction wallet. In the future, users can use MSAP to achieve account management, asset management, DApp interaction and other functions.",
    ThreeCategoryFirstQuestion:"Why are some original images not displayed in the synthesis success display?",
    ThreeCategoryFirstAnswer:"Because the third-party service we are currently using is not timely enough to obtain information for some assets, some assets’ image information has not been obtained, so they cannot be displayed on the platform.",
    ThreeCategorySecondQuestion:"Why can’t I see the contract in my contract list immediately after deploying it?",
    ThreeCategorySecondAnswer:"Our data synchronization service will process the data as soon as possible after a safe block height, so there may be a time difference of several seconds to minutes between the feedback from the node connected to your wallet.",
    ThreeCategoryThirdQuestion:"Why can’t I see the synthesized DC-NFT in my DC-NFT list immediately after synthesis?",
    ThreeCategoryThirdAnswer:"Just like the contract display delay, to ensure that the data can be parsed by the centralized service in the correct order, our data display will have a time difference of several seconds to minutes with the feedback from the node connected to your wallet.",
    ThreeCategoryFourthQuestion:"Why can’t the image of DC-NFT be displayed immediately in my backpack after synthesis?",
    ThreeCategoryFourthAnswer:"Because we will collect the original images of the underlying assets to synthesize the images of DC-NFT, depending on the size of the original images, this process may take a few seconds to several minutes.",
    ThreeCategoryFifthQuestion:"Why can’t I see the synthesized DC-NFT on Opensea?",
    ThreeCategoryFifthAnswer:"Opensea may actively hide NFTs that have not been traded on its platform, so you need to check the synthesized DC-NFT in the hidden list on Opensea platform, and unhide these DC-NFT from Opensea.",
   }
   
  let zh = {
    title:"常见问题解答",
    HelpYouTitle:"FAQ",
    HelpYouTitleDes:"浏览帮助主题",
    subTitle:"",
    up:"展开",
    down:"收起",
    mobileMenu:"问题类别",
    AllCategories:"全部类别",
    AnswerTitleDes:"我们将根据用户反馈不断更新常见问题解答列表。",
    CategoryTitleOne:"关于MSAP",
    CategoryTitleTwo:"合成操作相关问题",
    CategoryTitleThree:"合成结果相关问题",
    OneCategoryFirstQuestion:"MSAP是什么？",
    OneCategoryFirstAnswer:"MSAP是数字资产资产合成平台，为用户提供全新的资产管理、转移和交易方式。用户可以将手中任意的原始数字资产灵活的组合成一个NFT，也可以将组合出的NFT拆分得到原始资产。",
    OneCategorySecondQuestion:"为什么我们需要使用MSAP来进行资产合成？",
    OneCategorySecondAnswer:"目前NFT以及整个DeFi市场的流动性和资产多样性不足，导致赛道僵化，交易活跃度降低，用户持有的数字资产贬值。MASP通过数字资产自由合成的方式，创造无限的新资产和流动性，发现新的交易市场，激活数字资产的潜在价值 。",
    OneCategoryThirdQuestion:"通过MSAP的市场进行交易有什么优势？",
    OneCategoryThirdAnswer:"MSAP创新的交易市场，可以提高加密资产市场的流动性，提高用户的资产效率。通过MSAP所带来的资产多样性，可以有效激活市场的流动性和交易活跃度，更利于用户在NFT和DeFi交易市场上高效的获得更多收益。",
    OneCategoryFourthQuestion:"MSAP如何帮助用户获益？",
    OneCategoryFourthAnswer:"MSAP通过资产可组合性，创造出多样化的流动性，新的交易市场和交易结构，支撑用户通过更低的滑点，指数级的提高资产交易效率，帮助用户发现和抓住更多的交易机会。",
    TwoCategoryFirstQuestion:"MSAP协议可以将哪些资产作为底层资产进行合成？",
    TwoCategoryFirstAnswer:"MSAP协议最终将支持任意带有转移功能的资产，进行合成。目前平台开放了ERC 721、ERC 1155以及ERC 20三种资产的合成能力。",
    TwoCategorySecondQuestion:"合成得到的是什么资产？",
    TwoCategorySecondAnswer:"合成得到的是完全兼容ERC 721或ERC 1155资产，这种资产被我们命名为DC-NFT.目前平台开放的是将底层资产合成为ERC 721标准的DC-NFT。",
    TwoCategoryThirdQuestion:"被合成的底层资产数量是否有限制？",
    TwoCategoryThirdAnswer:"底层资产的数量是有限制的，这个限制的来源是区块的gas上限。目前阶段，为了更好的为更多的用户提供服务，我们以底层资产的合约加token id作为资产类型标识，限制了每个DC-NFT最多包含5个不同类型的资产。",
    TwoCategoryFourthQuestion:"合成后，这些被合成的底层资产还属于我么",
    TwoCategoryFourthAnswer:"合成交易完成后，底层资产仍然属于您，这是因为底层资产在进行合成后，您将得到代表这些底层资产的DC-NFT。底层资产完全由其合成的DC-NFT所代表，DC-NFT的所有者，就是这些DC-NFT所代表的底层资产的所有者。",
    TwoCategoryFifthQuestion:"合成出来的DC-NFT是否可以转移到其他地址？",
    TwoCategoryFifthAnswer:"是可以转移的，因为合成生成的DC-NFT是兼容ERC 721 或ERC 1155标准的，完全可以自由的进行转移。不过需要注意的是，谁持有DC-NFT，谁就有权限拿回其所代表的底层资产。",
    TwoCategorySixthQuestion:"合成得到的DC-NFT资产，可以再其他平台进行交易么？",
    TwoCategorySixthAnswer:"可以在其他平台交易，DC-NFT是完全兼容ERC 721或ERC 1155标准的，可以无限制的参与到各种NFT交易平台或相关项目中。",
    TwoCategorySeventhQuestion:"谁可以对DC-NFT进行拆分？拆分后的底层资产属于谁？",
    TwoCategorySeventhAnswer:"DC-NFT的所有者，可以对DC-NFT进行拆分，拆分后DC-NFT将被销毁，其所代表的底层资产将会转移到该DC-NFT所有者的钱包。",
    TwoCategoryEighthQuestion:"是否可以将MSAP的合约作为一个account abstraction钱包来使用？",
    TwoCategoryEighthAnswer:"可以。MSAP的一个重要方向，就是将作为AA钱包的一个具体实现。未来，用户可以通过MSAP来实现账户管理、资产的管理、DApp交互等功能。",
    ThreeCategoryFirstQuestion:"为什么合成成功的展示图中，有些原始图像无法展示出来？",
    ThreeCategoryFirstAnswer:"由于目前我们所使用的第三方服务，对于某些资产的信息获取不够及时，会导致有些资产的图像信息没有被获取到，因此无法在平台上展示。",
    ThreeCategorySecondQuestion:"为什么部署合约完成后，无法在我的合约列表内立即展示？",
    ThreeCategorySecondAnswer:"我们的数据同步业务，会尽可能的在一个安全的区块高度之后，在进行数据处理，因此可能会与您的钱包所连接的节点的反馈有十几秒钟到几分钟不等的时差。",
    ThreeCategoryThirdQuestion:"为什么合成DC-NFT后，无法在我的DC-NFT列表内立即展示？",
    ThreeCategoryThirdAnswer:"与合约展示延迟一样，为保证数据能够按照正确的顺序被中心化业务解析，我们的数据展示会与您的钱包所连接的节点的反馈有十几秒钟到几分钟不等的时差。",
    ThreeCategoryFourthQuestion:"为什么合成后，在我的背包里，DC-NFT的图像不能立即显示？",
    ThreeCategoryFourthAnswer:"因为我们会采集底层资产的原始图像，来进行DC-NFT图像的合成。根据原始图像的大小不同，这个过程可能会有几秒到几分钟的延迟。",
    ThreeCategoryFifthQuestion:"为什么我在Opensea上，无法看到我合成的DC-NFT？",
    ThreeCategoryFifthAnswer:"Opensea可能会主动隐藏未在其平台交易过的NFT，因此您需要在Opensea平台上的已隐藏列表查看合成的DC-NFT，并取消Opensea对这些DC-NFT的隐藏。",
   }
   
   let name = 'faq'
   
   export {
     name, en, zh
   }