import {i18nBuilder} from "../tools";

let i18n = i18nBuilder([
  require('./backpack'),//背包
  require('./operationRecord'),//操作记录
  require('./transactionRecord'),//转移记录
  require('./pointsRecord'),//积分记录
  require('./drawer'), 
  require('./mySeries'),
  require('./sidebar'),
  require('./order')
])

export default {
  i18n
}

