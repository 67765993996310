let en =  {
  Income:"My Income",
  TitleDes:"Showcase all your creative earnings",
  Back:"Back",
  SaveSeries:"Save series",
  Cancel:"Cancel",
  CreateSeries:"Create Series",
  Series:"Series",
  FloorPrice:"Floor Price",
  TradingVolume:"Trading Volume",
  Owner:"Owner",
  items:"items"
}

let zh = {
  Income:"我的收益",
  TitleDes:"展示您的所有创作收益",
  Back:"返回",
  SaveSeries:"保存系列",
  Cancel:"取消",
  CreateSeries:"创建系列",
  Series:"系列",
  FloorPrice:"地板价",
  TradingVolume:"交易量",
  Owner:"所有者",
  items:"作品数量"
}

let name = 'income'

export {
  name, en, zh
}