let en =  {
  Title:"Market",
  NFTMarket:"NFT Market",
  PrivateMarket:"Private Market",
  BlockTrading:"Block Trading",
  NFTMarketDes:"The DC-NFT exclusive trading market with underlying assets as NFT portfolios, reducing NFT trading costs, improving NFT circulation efficiency, and supporting fixed-price, auction and other trading methods.",
  PrivateMarketDes:"The private trading market for emerging or refinancing projects, to discover project value in advance and shorten the return cycle for investors. DC-NFTs on this market have project tokens as the underlying assets, and implement the agreed conditions for unlocking and releasing through decomposition rules.",
  BlockTradingDes:"The market offers trading DC-NFTs that contain bulk FT assets. Through decentralized technology and ZKP, it achieves lower transaction slippage and transaction fees, as well as more secure and privacy-protected block trading.",
  Button:"See More"
}

let zh = {
  Title:"市场",
  NFTMarket:"NFT市场",
  PrivateMarket:"私募市场",
  BlockTrading:"大宗交易市场",
  DCNFTLending:"DC-NFT 借贷市场",
  NFTMarketDes:"以底层资产为NFT组合的DC-NFT专属交易市场，降低NFT交易成本，提高NFT流通效率，支持固定价格、拍卖等交易方式。",
  PrivateMarketDes:"新兴或再融资项目的私募交易市场，提前发现项目价值，缩短投资者的回报周期。 该市场上的DC-NFT以项目代币作为底层资产，通过分解规则实现约定的解锁和释放条件。",
  BlockTradingDes:"市场提供包含大量 FT 资产的 DC-NFT 交易。 通过去中心化技术和ZKP，实现更低的交易滑点和交易费用，以及更安全和隐私保护的大宗交易。",
  DCNFTLendingDes:"",
  Button:"查看更多"
}

let name = 'market'

export {
  name, en, zh
}