let en =  {
  Back:"Back",
  SaveSeries:"Save series",
  Cancel:"Cancel",
  CreateSeries:"Create Series",
  TitleDes:"Deployment of the series' contracts",
  DeploymentNewContracts:"Deployment of new contracts",
  SelectExistingContract:"Select an existing contract",
  tips:"Default display user login wallet address, can not be modified",
  JumpWallet:"jump to your wallet",
  JumpWalletTips:"Please approve the transaction in the wallet to complete the contract deployment",
  Deployment:"Deployment",
  Continue:"Continue"
}

let zh = {
  Back:"返回",
  SaveSeries:"保存系列",
  Cancel:"取消",
  CreateSeries:"创建系列",
  TitleDes:"系列合约的部署",
  DeploymentNewContracts:"部署新合约",
  SelectExistingContract:"选择也有合约",
  tips:"默认显示用户登录钱包地址，不可修改",
  JumpWallet:"跳转到你的钱包",
  JumpWalletTips:"请在钱包中批准交易以完成合约部署",
  Deployment:"部署",
  Continue:"继续"
}

let name = 'create'

export {
  name, en, zh
}