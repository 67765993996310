let en =  {
  OnSale:"OnSale",
  SaleOut:"Sold",
  UnSoldTimeout:"Unsold for 10+ days",
  UnSold:"OnSale",
  Cancelled:"Cancelled",
  Details:"Details",
  CancelSale:"CancelSale",
  Chain:"Chain",
  Price:"Price",
  Status:"Status",
  Operate:"Operate",
  TimeOut:"timeOut",
  Day:"day",
  All:"All active listings",
  OnSaleFor:"on sale for",
  Completed:"Completed",
  OrderCompleted:"Order completed"
 }
 
 let zh = {
  OnSale:"挂售中",
  SaleOut:"已售出",
  UnSoldTimeout:"10+日未售出",
  UnSold:"未售出",
  Cancelled:"已取消",
  Details:"详情",
  CancelSale:"取消出售",
  Chain:"链",
  Price:"价格",
  Status:"状态",
  Operate:"操作",
  TimeOut:"已超时",
  Day:"天",
  All:"所有在售",
  OnSaleFor:"on sale for",
  Completed:"已完成",
  OrderCompleted:"已完成订单"
 }
 
 let name = 'order'
 
 export {
   name, en, zh
 }