let en =  {
  Nodata:"no content yet",
  Quantity:"Items",
  EditCollection:"Edit Contract",
  DeteleCollection:"Delete Contract",
  SearchInputPlaceholder:"Please enter the contract name"
 }
 
 let zh = {
  Nodata:"暂无内容",
  Quantity:"DC-NFT 数量",
  EditCollection:"修改系列",
  DeteleCollection:"删除系列",
  SearchInputPlaceholder:"请输入合约名称"
 }
 
 let name = 'mySeries'
 
 export {
   name, en, zh
 }