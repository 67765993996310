let en =  {
  title:"Transfer records",
  des:"The transfer records of the DC-NFT related to you",
  filter:"filter",
  filterEvent:"Event Type",
  filterSeries:"Series",
  filterChain:"Chain",
  Chian:"Chian",
  filterPrice:"Price",
  filterBtn:"Apply",

  startDate:"start date",
  endDate:"end date",
  event:"event",
  nftName:"Name",
  transaction:"Transaction Fees",
  quantity:"Quantity",
  from:"From",
  to:"To",
  date:"Age",
  mobileClear:"Clear",
  mobileDone:"Complete",


  sale:"sale",
  buy:"buy",
  transfer:"transfer",
  auction:"auction",
  return:"return",



  amount:"Amount",
  DCNFTID:"Token ID",
  txHash:"Transaction hash",
  SelectEvent:"Select Event",
  All:"All"
  
}

let zh = {
  title:"转移记录",
  des:"与您相关的DC-NFT的转移记录",
  filter:"筛选",
  filterEvent:"事件类型",
  filterSeries:"系列",
  filterChain:"链",
  Chian:"链",
  filterPrice:"交易价格",
  filterBtn:"应用",
  startDate:"开始时间",
  endDate:"结束时间",
  event:"事件",
  nftName:"名称",
  transaction:"交易费用",
  quantity:"数量",
  from:"从",
  to:"到",
  date:"时间",
  mobileClear:"清除",
  mobileDone:"完成",



  sale:"销售",
  buy:"购买",
  transfer:"转移",
  auction:"竞拍",
  return:"退回",




  amount:"数量",
  DCNFTID:"Token ID",
  txHash:"交易Hash",
  SelectEvent:"选择事件",
  All:"全部"
  

}

let name = 'transactionRecord'

export {
  name, en, zh
}