let en =  {
  PointsRecord:"Points Record",
  PointsRecordDes:"Show all your points",
  Back:"Back",
  Operate:"Operate",
  Price:"Price",
  EarnPoint:"Earn Point",
  GetTime:"Get Time",
  Synthesis:"Synthesis",
  Sale:"Sale",
  Buy:"Buy"
}

let zh = {
  PointsRecord:"积分记录",
  PointsRecordDes:"展示您的全部积分记录",
  Back:"返回",
  Operate:"操作",
  Price:"价格",
  EarnPoint:"获取积分",
  GetTime:"获取时间",
  Synthesis:"组合",
  Sale:"挂售",
  Buy:"购买"
}

let name = 'record'

export {
  name, en, zh
}