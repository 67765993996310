let en =  {
  Select:"Select",
  ChainContract:"Assets",
  PleaseSelectContracts:"assets type",
  SearchContract:"assets name",
  Cancel:"Cancel",
  Next:"Next",
  ChooseTips:"Destroy up to 5 assets at a time",
  NFT:"NFT",
  TokenId:"TokenId",
  ContractAddress:"ContractAddress",
  Chain:"Chain",
  Count:"Count",
  Define:"Confirm",
  Currency:"Currency",
  HoldCount:"HoldCount",
  PageCount:"PageCount",
  PackagingResults:"Synthesized",
  Collection:"Name",
  TokenID:"Token ID",
  NFTName:"NFT Name",
  ContinuePackaging:"Continue Packaging",
  ViewCollection:"View DC-NFT",
  Previous:"Previous",
  ContractName:"ContractName",
  Type:"Type",
  Name:"Name",
  Symbol:"Symbol",
  Address:"Address",
  subtitle:"List of deployed contracts",
  deployNamePlaceholder:"Please enter the contract name",
  deploySymbolPlaceholder:"Please enter the contract symbol",
  deployTips:"Default display user login wallet address, can not be modified",
  ContractType:"Contract Type",
  DeployContractTitle:"Deploy Contract",
  Deployment:"Deployment",
  JumpToWallet:"Will jump to your wallet",
  JumpToWalletTips:"Please approve the transaction in the wallet to complete the contract deployment",
  Status:"Status",
  Operate:"Operate",
  Selected:"Selected",
  AssetsType:"Assets Type",
  NFTAssets:"NFT Assets",
  MixedAsset:"DC-NFT Asset",
  Owner:"Owner",
  PleaseEnterName:"Please enter name",
  PleaseEnterSymbol:"Please enter symbol",
  PageCountMax:"(Max 6 decimal places)",
  LastPrice:"Last Price",
  GetPoints:"Get points",
  AssetSynthesizeSuccess:"Asset Synthesize Success"
}

let zh = {
  Select:"选择",
  ChainContract:"链资产",
  PleaseSelectContracts:"请选择合约",
  SearchContract:"资产名称",
  Cancel:"取消",
  Next:"下一步",
  ChooseTips:"一次拆分最多 5 项资产",
  NFT:"NFT",
  TokenId:"TokenId",
  ContractAddress:"合约地址",
  Chain:"链",
  Count:"数量",
  Define:"确定",
  Currency:"币种",
  HoldCount:"持有数量",
  PageCount:"打包数量",
  PackagingResults:"合成完毕",
  Collection:"名称",
  TokenID:"Token ID",
  NFTName:"NFT名称",
  ContinuePackaging:"继续合成",
  ViewCollection:"查看DC-NFT",
  Previous:"上一步",
  ContractName:"合约名称",
  Type:"类型",
  Name:"名称",
  Symbol:"符号",
  Address:"地址",
  subtitle:"已部署的合约列表",
  deployNamePlaceholder:"请输入合约名称",
  deploySymbolPlaceholder:"请输入合约符号",
  deployTips:"默认显示用户登录钱包地址，不可修改",
  ContractType:"合约类型",
  DeployContractTitle:"部署合约",
  Deployment:"部署",
  JumpToWallet:"将跳转到您的钱包",
  JumpToWalletTips:"请在钱包中批准交易以完成合约部署",
  Status:"Status",
  Operate:"Operate",
  Selected:"Selected",
  AssetsType:"资产类型",
  NFTAssets:"NFT资产",
  MixedAsset:"DC-NFT资产", 
  Owner:"所有者",
  PleaseEnterName:"请输入名称",
  PleaseEnterSymbol:"请输入符号",
  PageCountMax:"(最大6位小数)",
  LastPrice:"上次购买价格",
  GetPoints:"获取积分",
  AssetSynthesizeSuccess:"资产组合成功"
}

let name = 'popUps'

export {
  name, en, zh
}