let en:any =  {
  Home:"Home",
  Market:"Market",
  DCNFTMarket:"DC-NFT Market",
  NFTMarket:"DC-NFT Market",
  PrivateMarket:"Private Market",
  BlockTrading:"Block Trading",
  Factory :"Factory ",
  Resources :"Resources",
  Dividends:"Earn",
  Explorer:"Explorer",
  Docs:"Docs",
  IntroductionDocs:"Introduction docs",
  DevelopmentDocs:"Development docs",
  GitHub:"GitHub",
  PersonalCenter:"PersonalCenter",
  ConnectWallet:"Connect Wallet",
  notSupport:"This network is not supported at this time,please switch to",
  toggle:"please switch!",
  searchPlaceholder:"Type what you want to search for here",
  Items:"Items",
  ItemNotFound:"Item Not Found",
  FAQ:"FAQ",
  Language:"Language",
  changeLanguage:"Change Language",
  Or:"or",
  MyAssets:"Assets",
  MyRecord:"Operations",
  MyOrder:"Orders"
}

let zh:any = {
  Home:"首页",
  Market:"市场",
  DCNFTMarket:"DC-NFT市场",
  NFTMarket:"DC-NFT市场",
  PrivateMarket:"私募市场",
  BlockTrading:"大宗交易市场",
  Factory :"工厂",
  Resources :"资源和媒体",
  Dividends:"分红",
  Explorer:"浏览器",
  Docs:"文档",
  IntroductionDocs:"介绍文档",
  DevelopmentDocs:"开发文档",
  GitHub:"GitHub",
  PersonalCenter:"个人中心",
  ConnectWallet:"连接钱包",
  notSupport:"目前不支持该网络，请切换至",
  toggle:"请切换!",
  searchPlaceholder:"在此处输入想要搜索的内容",
  Items:"项目",
  ItemNotFound:"未找到项目",
  FAQ:"FAQ",
  Language:"语言",
  changeLanguage:"切换语言",
  Or:"或",
  MyAssets:"我的资产",
  MyRecord:"操作记录",
  MyOrder:"订单列表"
}

let name = 'nav'

export {
  name, en, zh
}