const blockTrading = [
  {
    path: '/block-trading',
    name:'blockTrading',
    component: () => import('../../../views/market/blockTrading/index.vue'),
  }
]



export default blockTrading

