import main from './main'
import home from './home'
import popUps from './popUps'
import  earn from './earn'
import market from './market'
import userCenter from './userCenter'
import factory from './factory'
import points from './points'
import series from './series'
import docs from './docs'

export default {
  Main: main.i18n['zh'],
  Home: home.i18n['zh'],
  PopUps: popUps.i18n['zh'],
  Earn:earn.i18n['zh'],
  Market:market.i18n['zh'],
  UserCenter:userCenter.i18n['zh'],
  Factory: factory.i18n['zh'],
  Points: points.i18n['zh'],
  Series: series.i18n['zh'],
  Docs: docs.i18n['zh'],
}