import router from '@/router'
import chainId from '@/js/chain/chainID'
import wallet from "@/js/web3/web3";
import { initContracts, contractAddress } from "@/js/web3/contract";
import Decimal from "decimal.js";
import { getUserBalance } from '@/js/contract/MsapPoints'

let address = window.localStorage.getItem('address');
let walletForm = window.localStorage.getItem('wallet');
let walletChainId = window.localStorage.getItem('walletChainId');

export default {
  state: {
    address: (address === "null" || address === null || address === "undefined" || address === undefined) ? "" : address,
    chainName: '',
    symbol: '',
    ChainId: (walletChainId === "null" || walletChainId === null || walletChainId === "undefined" || walletChainId === undefined)?"" : walletChainId,
    walletType: (walletForm === "null" || walletForm === null || walletForm === "undefined" || walletForm === undefined) ? "" :  walletForm,
    navActive:'',
    Modal:false,
    PlatformChainId:window.localStorage.getItem('chainId')?Number(window.localStorage.getItem('chainId')):10000000,
    points:"0",
    balance:"0"
  },
  getters: {
    address: state => state.address,
    chainName: state => state.chainName,
    symbol: state => state.symbol,
    ChainId: state => state.ChainId,
    walletType: state => state.walletType,
    navActive: state => state.navActive,
    Modal: state => state.Modal,
    PlatformChainId: state => state.PlatformChainId,
    points: state => state.points,
    balance: state => state.balance,
  },
  mutations: {
    updateAccountsChanged(state, payload) {
      state.address = payload;
      window.localStorage.setItem('address',payload);
    },
    updateSymbol(state, payload) {
      state.symbol = payload;
    },
    updateChainName(state, payload) {
      state.ChainId = payload
      window.localStorage.setItem('walletChainId',payload)
      let chainData = chainId.find(item => item.ID===payload)
      if(chainData&&state.address){
          state.chainName = chainData.symbol;
          state.symbol = chainData.nativeCurrency.symbol;
          window.localStorage.setItem('chainId',JSON.stringify(chainData.PlatformChainId))
          state.PlatformChainId = chainData.PlatformChainId;
      }else{
        window.localStorage.setItem('chainId',"10000000")
        state.PlatformChainId = 10000000;
        state.chainName = payload;
      }
      // for (let i in chainId) {
      //   if (chainId[i].ID === payload&&state.address) {
      //     state.chainName = chainId[i].symbol;
      //     state.symbol = chainId[i].nativeCurrency.symbol;
      //     window.localStorage.setItem('chainId',JSON.stringify(chainId[i].PlatformChainId))
      //     state.PlatformChainId = chainId[i].PlatformChainId;
      //     break
      //   }else{
      //     window.localStorage.setItem('chainId',"10000000")
      //     state.PlatformChainId = 10000000;
      //     state.chainName = payload;
      //   }
      // }
    },
    updatePolkadotChainName(state, payload){
      state.chainName = payload;
    },
    updateChainId(state, payload) {
      state.ChainId = payload;
    },
    updateWalletType(state, payload) {
      window.localStorage.setItem('wallet',payload)
      state.walletType = payload;
    },
    updateNavActive(state, payload){
      state.navActive = payload;
    },
    updateModal(state, payload){
      state.Modal = payload
    },
    updatePoints(state, payload){
      state.points = payload
    },
    updateBalance(state, payload){
      state.balance = payload
    }
  },
  actions: {
    enableMetaMaskWallet({commit, state, rootState, dispatch}) {
      (window as any).ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
        console.log("accounts=====",accounts)
        commit('CHANGE_IS_LOGIN',true)
        dispatch('connectWalletOperate', {address:accounts[0],walletType:"MetaMask"})
      }).catch((error) => {
        commit('updateConnectWalletLoading', false)
        commit('updateConnectWalletErrLoading', true)
      })
    },
    async connectWalletOperate({ commit, state, rootState, dispatch },params){
      console.log('params===',params)
      commit('updateAccountsChanged', params.address)
      commit('updateConnectWallet', false)
      commit('updateConnectWalletErrLoading', false)
      commit('updateConnectWalletLoading', false)
      commit('updateWalletType',params.walletType)
      let account = await (window as any).ethereum.request({
        method: "eth_requestAccounts",
      });
      wallet.loadWallet().then(() => {
        wallet.getChainId().then((res)=>{
          commit("updateChainId", res);
          commit("updateChainName", res);
          (window as any).addressMap = contractAddress();
          // dispatch('getPointAndBalance')
        })
      })
     
    },
    async switchChain({ commit, state }, parpms) {
      try {
        await (window as any).ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: parpms.ID }],
        });
        parpms.runFunction()
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            const walletParam = {
              "chainId": parpms.ID,
              "chainName": parpms.chainName,
              "rpcUrls": [parpms.rpcUrl],
              "nativeCurrency": parpms.nativeCurrency,
              "blockExplorerUrls": [parpms.blockExplorerUrls]
            }
            let Param = JSON.parse(JSON.stringify(walletParam))
            await (window as any).ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [Param]
            });
            parpms.runFunction()
          } catch (addError) {

          }
        }
      }
    },
    async getPointAndBalance({commit, state, rootState, dispatch}){
      // try{  
      //   let accountBalance = await (window as any).web3.eth.getBalance(state.address);
      //   accountBalance = new Decimal(accountBalance).div(new Decimal(`1e${18}`)).toString(); 
      //   accountBalance = Number(accountBalance).toFixed(3);
      //   commit('updateBalance',accountBalance)
      //   let point = await getUserBalance(state.address);
      //   commit('updatePoints',point)
      //   console.log('更新下余额和积分',accountBalance,point)
      // }catch(error){

      // }
      
    }   
  }
}
