let en =  {
  How:"How",
  Works:"works",
  SynthesisTitle:"Synthesize",
  SynthesisDes:"Through the MSAP factory, combine any FT and NFT assets, synthesize dynamic composable NFTs (DC-NFTs).",
  MarketTitle:"Trade",
  MarketDes:"Sell, buy or auction DC-NFTs in a dedicated marketplace and gain platform points.",
  DestructionTitle:"Decompose",
  DestructionDes:"Decompose your DC-NFT with MSAP factory to get the underlying assets.",
  PointsTitle:"Earn",
  PointsDes:"Earn MSAP rewards by freezing platform points to the MSAP mining pool.",
}

let zh = {
  How:"如何",
  Works:"工作",
  SynthesisTitle:"合成",
  SynthesisDes:"通过MSAP工厂，组合任何FT和NFT资产，合成动态可组合NFT（DC-NFT）。",
  MarketTitle:"交易",
  MarketDes:"在专门的市场上出售、购买或拍卖 DC-NFT 并获得平台积分。",
  DestructionTitle:"拆分",
  DestructionDes:"通过MSAP工厂分解你的DC-NFT以获得底层资产",
  PointsTitle:"奖励",
  PointsDes:"通过冻结MSAP矿池的平台积分来赚取MSAP奖励。",
}

let name = 'howWorks'

export {
  name, en, zh
}