let en =  {
  BuySuccess:"Purchase Success",
  BuySuccessTips:"Your DCNFT has been saved to your backpack",
  BuyError:"Purchase Error",
  BuyErrorTips:"DCNFT purchase failed",
  BuyLoadingTitle:"Your purchase is being processed...",
  TransactionHash:"Transaction hash",
  The:"The",
  BuyLoadingTips:"asset you purchased is being processed, and the processing time is estimated to be",
  Minute:"minute",
  BuyCompleted:"Purchase completed",
  DCNFTFee:"DC-NFT Fee",
  GasFee:"Gas Fee",
  TotalPrice:"Total price",
  ViewDetails:"View Details",
  GetPoints:"Get Points"
}

let zh = {
  BuySuccess:"购买成功",
  BuySuccessTips:"您的DCNFT已存至您的背包",
  BuyError:"购买失败",
  BuyErrorTips:"DCNFT购买失败",
  BuyLoadingTitle:"您的购买正在处理中...",
  TransactionHash:"交易哈希",
  The:"您购买的",
  BuyLoadingTips:"资产正在处理中，处理时间预计",
  Minute:"分钟",
  BuyCompleted:"购买完成",
  DCNFTFee:"DC-NFT费用",
  GasFee:"汽油费用",
  TotalPrice:"总价",
  ViewDetails:"查看详情",
  GetPoints:"获取积分"
}

let name = 'buy'

export {
  name, en, zh
}