const NFTMarket = [
  { 
    path: '/nft-market',
    name:'NFTMarket',
    component: () => import('@/views/market/nftMarket/index.vue'),
  },
  {
    path: '/nft-market/series/:chain/:contract',
    name: 'seriesDetail',
    component: () => import('@/views/market/nftMarket/series.vue')
  }, 
  {
    path: '/nft-market/assets/:chain/:contract/:tokenid',
    name: 'nftDetail',
    component: () => import('@/views/market/nftMarket/assets.vue')
  }
]


export default NFTMarket

