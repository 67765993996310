import {i18nBuilder} from "../tools";

let i18n = i18nBuilder([
  require('./edit'),
  require('./income'),
  require('./mySeries'),
  require('./create'),
  require('./delete')
])

export default {
  i18n
}

