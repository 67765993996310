let en =  {

 collectedTab:"Collected",
 createdTab:"Synthesized",
 sellTabs:"Listing",
 hasSplitTab:"Decomposed",
 bidTab:"",


 filterCombination:"Combination Type",
 filterChain:"Chain",
 filterSeries:"Series",
 filterStatus:"Status",
 filterPrice:"Price",

 nftTitle:"nftTitle",
 nftName:"",
 nftDes:"",
 nftPrice:"",
 nftMaxPrice:"MaxPrice",
 nftDeal:"nftDeal",
 endBuy:"endBuy",
 num:"num",
 edTime:"edTime",
 nftBtn:"get it",
 applyBtn:"apply",


statusUp:"up",
statusDown:"down",

 
combinationDex:"NFT Market",
combinationBlock:"Private Market",
combinationPrivate:"Block Trading",


  
  sale:"sale",
  cancelSale:"cancelSale",
  split:"Decompose",
  combinationType:"combinationType",
 
  noDataText:"no-content",
  collectedBtnText:"Go to the mall to buy",
  createdBtnText:"to create",
  hasSplitBtnText:"to split",
  bidBtnText:"Go to the mall to buys",
  sellSuccessTips:"Listed for sale successfully",
  sellSuccess:"Your DC-NFT has been listed on the market for sale",
  sellErrorTips:"Listing failed",
  sellError:"Your DC-NFT listing failed",
  SalePrice:"Sale Price",
  NotListedSale:"NotListedSale"
 }
 
 let zh = {
   collectedTab:"已收藏",
   createdTab:"已创建",
   sellTabs:"挂售中",
   hasSplitTab:"已拆分",
   bidTab:"已出价",
 
   filterCombination:"组合类型",
   filterChain:"链",
   filterSeries:"系列",
   filterStatus:"状态",
   filterPrice:"价格",
  
  
   nftTitle:"藏品",
   nftName:"",
   nftDes:"",
   nftPrice:"",
   nftMaxPrice:"最高出价",
   nftDeal:"竞拍成交",
   endBuy:"最后购买",
   num:"数量",
   edTime:"截止时间",
   nftBtn:"立即领取",
   applyBtn:"应用",
  
 
   statusUp:"已出售",
   statusDown:"未出售",

   combinationDex:"版权资产",
   combinationBlock:"大宗资产",
   combinationPrivate:"私募资产",





   sale:"出售",
   cancelSale:"取消出售",
   split:"拆分",
 
 
 
   noDataText:"暂无内容",
   collectedBtnText:"去商城购买",
   createdBtnText:"去创建",
   hasSplitBtnText:"去拆分",
   bidBtnText:"去商城购买",
   sellSuccessTips:"挂售成功",
   sellSuccess:"您的DC-NFT已挂到市场进行销售",
   sellErrorTips:"挂售失败",
   sellError:"您的DC-NFT挂售失败",
   SalePrice:"挂售价格",
   NotListedSale:"未挂售"
 }
 
 let name = 'backpack'
 
 export {
   name, en, zh
 }