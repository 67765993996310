import {i18nBuilder} from "../tools";

let i18n = i18nBuilder([
  require('./Nav'),
  require('./About'),
])

export default {
  i18n
}

