const privateMarket = [
  {
    path: '/private-market',
    name:'privateMarket',
    component: () => import('../../../views/market/privateMarket/index.vue'),
  }
]



export default privateMarket

