import ERC20Token from '@/assets/jsonFile/abis/ERC20-ABI.json'
import ERC20Faucet from "@/assets/jsonFile/abis/ERC20Faucet.json"
// import setChainID from '@/js/chain/chainID'
import chainIdList from '@/js/chain/chainID'
import store from '@/store'
import { computed } from 'vue';
const ChainId = computed(()=>store.state.wallet.ChainId);

export const contractAddress = () =>{
    let networkData = chainIdList
    let currentNetWork= networkData.filter((item)=>{ return item.ID===ChainId.value})
    if(currentNetWork.length!==0){
        return currentNetWork[0].contractAddress
    }
}
export const initContracts = async () => {
    let address = await contractAddress()
    return {};
};

