import main from './main'
import home from './home'
import popUps from './popUps'
import earn from './earn'
import market from './market'
import userCenter from './userCenter'
import factory from './factory'
import points from './points'
import series from './series'
import docs from './docs'
export default {
  Main: main.i18n['en'],
  Home: home.i18n['en'],
  PopUps: popUps.i18n['en'],
  Earn:earn.i18n['en'],
  Market:market.i18n['en'],
  UserCenter:userCenter.i18n['en'],
  Factory: factory.i18n['en'],
  Points: points.i18n['en'],
  Series: series.i18n['en'],
  Docs: docs.i18n['en'],
}