let en =  {
  title:'Cancel Sell',
  seriesName:'seriesName',
  desOne:"Please confirm whether to cancel the sale",
  desTwo:"Your DC NFT is being viewed by multiple users",
  CancelSaleSuccess:"CancelSale Success",
  CancelSaleSuccessTips:"Your DC-NFT has been cancelled.",
  CancelSaleError:"CancelSale Error",
  CancelSaleErrorTips:"Your DC-NFT cancellation sale failed",
}

let zh = {
  title:'取消出售',
  seriesName:'系列名称',
  desOne:"请确认是否取消销售",
  desTwo:"您的 DC NFT 正在被多个用户查看",
  CancelSaleSuccess:"取消出售成功",
  CancelSaleSuccessTips:"您的DC-NFT已取消销售",
  CancelSaleError:"取消出售失败",
  CancelSaleErrorTips:"您的DC-NFT取消出售失败",
}

let name = 'cancelSell'

export {
  name, en, zh
}