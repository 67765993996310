import { createRouter, createWebHashHistory } from 'vue-router'

import wallet from "@/js/web3/web3"
import store from '../store'
import { initContracts, contractAddress } from "@/js/web3/contract"
import home from './modules/home'
import factory from './modules/factory'
import nftMarket from './modules/market/nftMarket'//nft市场
import privateMarket from './modules/market/privateMarket'//private市场
import blockTrading from './modules/market/blockTrading'//blockTrading市场
import earn from './modules/earn'//分红
import mySeries from './modules/mySeries'//我的系列
import userCenter from './modules/userCenter'
import points from './modules/points'


import faq from "./modules/docs/faq"
let routes =[{
  path: '/',
  name: 'mian',
  redirect: '/home',
  component: () => import('@/layout/Layout.vue'),
  children:[
    home,
    factory,
    ...nftMarket,
    ...privateMarket,
    ...blockTrading,
    // ...earn,
    // ...points,
    userCenter,
    ...faq,
    ...mySeries
  ]
}]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, _from, next:any) => {
  const accountSome = to.matched.some((record) => record.name === 'account')
  if(!(to.name==='decompose'&&_from.name==='synthesize' || to.name==='synthesize'&&_from.name==='decompose'|| accountSome)){
    window.scroll(0,0)
  }
  store.commit('updateNavActive', to.name)
  store.commit('setSearchValue', '')
  store.commit('updateModal',false)
  store.dispatch('changeDrawer', false)
  next()
  // wallet.isMetaMask().then((res)=>{
  //   if(!res){
  //     next()
  //   }else{
  //     wallet.loadWallet().then(() => {
  //       wallet.getChainId().then( async(res) => {
  //         let account = await (window as any).ethereum.request({ method: 'eth_accounts' });
  //         if(account.length !== 0){
  //           store.commit('updateChainId', res)
  //           store.commit('updateChainName', res)
  //           if(store.state.nav.isLogin){
  //             store.commit('updateAccountsChanged',(window as any).ethereum.selectedAddress)
  //           }
  //           store.commit('updateWalletType','MetaMask');
  //           (window as any).contract = await initContracts();
  //           (window as any).addressMap = await contractAddress();
  //         }
  //         next()
  //       })
  //     })
  //   }
  // })
})

export default router
