let en =  {
  BonusPoints:"Bonus Points",
  DividendIncome:"Dividend income",
  BonusPointsDes:"Point reward copywriting introduction point reward copywriting introduction point reward copywriting ",
  DividendIncomeDes:"Dividend income copywriting introduction Dividend income copywriting introduction",
  AccessMethod:"Access Method",
  MyPoints:"My Points",
  PointsRecord:"Points Record",
  GoFactory:"Go Factory",
  GoMarket:"Go Market",
  ViewRules:"View Rules",
  Next:"Next",
  Previous:"Previous",
  UseOfPoints:"Use of Points",
  ComingSoon:"ComingSoon",
  BonusPointsDesOne:"The following is a detailed description of the text The following is a detailed description of the text The following is a detailed description of the text",
  BonusPointsDesTwo:"The following is a detailed description of the text The following is a detailed description of the text The following is a detailed description of the text",
  BonusPointsDesThree:"The following is a detailed description of the text The following is a detailed description of the text The following is a detailed description of the text",
  DividendIncomeDesOne:"The following is a detailed description of the text The following is a detailed description of the text The following is a detailed description of the text",
  DividendIncomeDesTwo:"The following is a detailed description of the text The following is a detailed description of the text The following is a detailed description of the text",
  DividendIncomeDesThree:"The following is a detailed description of the text The following is a detailed description of the text The following is a detailed description of the text",
  ClaimPoints:"ClaimPoints",
}

let zh = {
  BonusPoints:"积分奖励",
  DividendIncome:"分红收益",
  BonusPointsDes:"积分奖励文案介绍积分奖励文案介绍积分奖励文案介绍积分奖励文案介绍积分奖励文案",
  DividendIncomeDes:"分红收益文案介绍分红收益文案介绍分红收益文案介绍",
  AccessMethod:"获取方法",
  MyPoints:"我的积分",
  PointsRecord:"积分记录",
  GoFactory:"前往工厂",
  GoMarket:"前往市场",
  ViewRules:"查看规则",
  Next:"下一步",
  Previous:"上一步",
  UseOfPoints:"积分用处",
  ComingSoon:"敬请期待",
  BonusPointsDesOne:"以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案",
  BonusPointsDesTwo:"以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案",
  BonusPointsDesThree:"以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案",
  DividendIncomeDesOne:"以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案",
  DividendIncomeDesTwo:"以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案",
  DividendIncomeDesThree:"以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案以下是具体介绍的文案",
  ClaimPoints:"领取积分",
}

let name = 'detail'

export {
  name, en, zh
}