let en =  {
  title:'Private Market',
  des:'The market built in the form of physical futures carried by DC-NFT, so that the project value can be discovered in advance and the investment return cycle can be shortened.',
  btn:"Coming Soon",
  advantages:"Advantages",
  desOne:"A new way of liquidity supply.",
  desTwo:"An innovative fundraising channel.",
  desThree:"Discover project value through market transactions.",
  desFour:"Shorten the investment return cycle.",
}

let zh = {
  title:'私有市场',
  des:'以DC-NFT承载的实物期货形式构建的市场，可以提前发现项目价值，缩短投资回报周期。',
  btn:"即将推出",
  advantages:"优势",
  desOne:"流动性供给的新方式。",
  desTwo:"创新筹资渠道。",
  desThree:"通过市场交易发现项目价值。",
  desFour:"缩短投资回报周期。",
}

let name = 'private'

export {
  name, en, zh
}