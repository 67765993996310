import { createI18n } from 'vue-i18n';
import LangEN from './en'
import LangZH from './zh'
import {GetLang} from "./localStorage"

const i18n = createI18n({
    locale: GetLang('en'),
    messages: {
        'en': LangEN,
        'zh': LangZH,
    },
    globalInjection: true,
    legacy: false,
});

export default i18n