let en =  {
  title:'Block Trading Market',
  des:'A block trading market with privacy protection and extremely low transaction fees, achieving safe and efficient bulk asset transactions.',
  btn:"Coming Soon",
  advantages:"Advantages",
  desOne:"Zero slippage, reduce trading losses.",
  desTwo:"Complete asset exchange in one transaction, greatly reducing transaction costs.",
  desThree:"Providing reliable privacy protection through ZKP technology.",
  desFour:"Decentralized trading mode, reducing intermediary risks.",
}

let zh = {
  title:'大宗交易市场',
  des:'隐私保护、交易费用极低的大宗交易市场，实现安全高效的大宗资产交易。',
  btn:"即将推出",
  advantages:"优势",
  desOne:"零滑点，减少交易损失。",
  desTwo:"一笔交易内完成资产交换，极大降低交易成本。",
  desThree:"通过ZKP技术提供可靠的隐私保护。",
  desFour:"去中心化的交易模式，降低中介风险。",
}

let name = 'block'

export {
  name, en, zh
}