let chainId = [
  {
    symbol:'ETH',
    name: "Ethereum-Mainnet",
    url: require("@/assets/common/eth.png"),
    ID: "0x1",
    NumberID:"1",
    PlatformChainId:1,
    rpcUrl: "https://mainnet.infura.io/v3/",
    blockExplorerUrls: "https://etherscan.io",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    isStart: true,
    isInvisible: false,
    contractAddress: {

    }
  },
  {
    symbol:'BSC',
    name: "BSC-Mainnet",
    url: require("@/assets/common/chainIcon/BSCIcon.png"),
    ID: "0x38",
    NumberID:"56",
    PlatformChainId:3,
    chainName: "Binance Smart Chain Mainnet",
    rpcUrl: "https://bsc-dataseed1.binance.org",
    blockExplorerUrls: "https://bscscan.com",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18
    },
    isStart: true,
    isInvisible: false,
    contractAddress: {
      NFTFactoryAddress:"0xD371Ef8a5B73bd2499BB57917FeC98E6bb15Bb95"
    }
  },
  {
    symbol:"Polygon",
    name: "Polygon-Mainnet",
    onlinkName:"Polygon",
    url: require("@/assets/common/polygon.png"),
    greyIcon: require("@/assets/common/chains/polygon.png"),
    activeIcon: require("@/assets/common/chains/polygon-active.png"),
    ID: "0x89",
    NumberID:"137",
    PlatformChainId:2,
    chainName: "Polygon Mainnet",
    rpcUrl: "https://polygon-rpc.com",
    blockExplorerUrls: "https://polygonscan.com",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    isStart: true,
    isInvisible: true,
    contractAddress: {
      factoryAddress:"0x5824Fe4AA8f0412C778Af284d4a81f374Ab12Fdc",
      operatorAddress:"0x5f87422AD4a1e01D19F2024B95B060bb5ec76905",
      marketAddress:"0x968d0BA7daF041F09e4761d7D53e2Dff53166C8b",
      NftTransferProxy:"0x0d7aa3AFB285a36b3de46d2c923CBBFc63c061B5"
      // marketAddress:"0x2D7A5C44dA52ab87e1d93aB60625a0F83c040F9b",
      // NftTransferProxy:"0xc3cad2B4fB73A4d3b10Aa423dEe4f714b904B9eD"
    }
  },
  {
    symbol:"Avalanche",
    onlinkName:"Avax",
    name: "Avalanche C-Chain",
    url: require("@/assets/common/avax.png"),
    greyIcon: require("@/assets/common/chains/avalanche.png"),
    activeIcon: require("@/assets/common/chains/avalanche-active.png"),
    ID: "0xa86a",
    NumberID:"43114",
    PlatformChainId:4,
    chainName: "Avalanche C-Chain",
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
    blockExplorerUrls: "https://snowtrace.io",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18
    },
    isStart: true,
    isInvisible: true,
    contractAddress: {
      factoryAddress:"0x51543e8C48281751192A15f53243101879F1135D",
      operatorAddress:"0x5824Fe4AA8f0412C778Af284d4a81f374Ab12Fdc",
      marketAddress:"0x375AD9179DcdA88fE85c7A6a89ec618048A56B54",
      NftTransferProxy:"0xF8B67BbFf09DBB6Ff8c7712e88e100E45b5B4002"
      // factoryAddress:"0xdB9Fe315A1351034D8B547589E358D31eB529dFB",
      // operatorAddress:"0xdeAA1F90279C610Bf8c4fD83E56dF5Ba0069cf69",
      // marketAddress:"0x0839CB2F2c36840C19001CDC41A16870f61FF327",
      // NftTransferProxy:"0xF2Eb6E6f32257FF66C1074AaB4c1a0843B3d37e7"
    }
  },
  {
    symbol:"Sepolia",
    onlinkName:"Sepolia",
    name: "Sepolia",
    url: require("@/assets/common/eth.png"),
    greyIcon: require("@/assets/common/chains/eth.png"),
    activeIcon: require("@/assets/common/chains/eth-active.png"),
    ID: "0xaa36a7",
    NumberID:"11155111",
    PlatformChainId:100001,
    chainName: "Sepolia",
    rpcUrl: "https://sepolia.infura.io/v3/",
    blockExplorerUrls: "https://sepolia.etherscan.io",
    nativeCurrency: {
      name: "SepoliaETH",
      symbol: "SepoliaETH",
      decimals: 18
    },
    isStart: true,
    isInvisible: false,
    contractAddress: {
      factoryAddress:"0xDC2D967487e6293c7eb4cB9B2ABe16d838167645",
      operatorAddress:"0x1B9AFD5BDBb2505f4857cD61BCf86AC46C63130F",
      marketAddress:"0x90EE51A26C575482cee12B7fff1863B0e71AAa45",
      NftTransferProxy:"0x0B62592E8B099a06041Df0B92c7A151810c42681",
      pointAddress:"0x429A3F175b69c08731Bb96d49860A4310CBf2911"
    }
  }
]

export default chainId