let en =  {
  Title:"Are you sure you want to delete this series?",
  TitleDes:"This operation will delete the series and hide all its DC NFT",
  Back:"Back",
  Cancel:"Cancel",
  DeleteSeries:"Delete Series",
}

let zh = {
  Title:"您确定要删除此系列吗？",
  TitleDes:"本次操作将删除此系列并隐藏其所有的DC NFT",
  Back:"返回",
  Cancel:"取消",
  DeleteSeries:"删除系列",
}

let name = 'delete'

export {
  name, en, zh
}