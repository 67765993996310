import { stat } from "fs";

export default {
  state: {
    isMobile: false, // 是否为移动端
    isDrawer: false, // 是否展开移动端菜单
    isCenter:false, //是否展开个人中心
    isLogin:localStorage.getItem('isLoggedIn') === 'true', //是否登录
    searchValue: ''
  },
  getters: {
    isMobile: (state) => state.isMobile,
    isDrawer: (state) => state.isDrawer,
    isCenter: (state) => state.isCenter,
    isLogin: (state) => state.isLogin,
    searchValue: (state) => state.searchValue
  },
  mutations: {
    CHANGE_IS_MOBILE: (state, flag) => {
      state.isMobile = flag;
    },
    CHANGE_IS_DRAWER: (state, flag) => {
      state.isDrawer = flag;
    },
    CHANGE_IS_CENTER: (state, flag) => {
      state.isCenter = flag;
    },
    CHANGE_IS_LOGIN: (state, flag) => {
      state.isLogin = flag;
      localStorage.setItem('isLoggedIn', flag)
    },
    setSearchValue(state, flag) {
      state.searchValue = flag
    }
  },
  actions: {
    changeMobile: ({ commit }, flag) => {
      commit('CHANGE_IS_MOBILE', flag);
    },
    changeDrawer: ({ commit }, flag) => {
      commit('CHANGE_IS_DRAWER', flag);
    },
    changeCenter: ({ commit }, flag) => {
      commit('CHANGE_IS_CENTER', flag);
    },
    changeLogin: ({ commit }, flag) => {
      console.log('是否登录==',flag)
      commit('CHANGE_IS_LOGIN', flag);
    }
  }
}
