let en =  {
  NFTSynthesis:"NFT synthesis",
  FreePortfolioAssets:"DC-NFT synthesis",
  MixedAssets:"Mixed asset DC-NFT",
  PrivateAssets:"Private asset synthesis",
  BulkAssets:"Bulk asset synthesis",
  PhysicalFutures:"Physical futures",
  FTsCirculation:"FTs in circulation",
  ContractManagement:"Management",
  PortfolioAssets:"Synthesize",
  NFTSDes:"NFTS",
  DCNFTsDes:"DC-NFT",
  MixedToken:"NFT/FT",
  MixedDCNFT:"DC-NFT asset",
  PrivateToken:"MSAP",
  PrivateDCNFT:"Physical futures",
  BulkToken:"FTs in circulation",
  BulkDCNFT:"Bulk asset synthesis",
  CommingSoone:"coming soon",
  packLoadingTitle:"Synthesize",
  JumpWallet:"Please approve the transactions",
  JumpWalletTips:"Multiple transactions may be initiated during the synthesis process, please approve them one by one. The whole synthesis process is estimated to take about",
  Minute:"minutes.",
  ContinueSynthesize:"Continue synthesize",
  ReceivePoints:"Receive points",
  GoBackpack:"Go backpack"
}

let zh = {
  NFTSynthesis:"NFT资产",
  FreePortfolioAssets:"DC-NFT合成",
  MixedAssets:"混合资产",
  PrivateAssets:"私募资产",
  BulkAssets:"大宗资产",
  PhysicalFutures:"实物期货",
  FTsCirculation:"已流通的FTs",
  ContractManagement:"合约管理",
  PortfolioAssets:"组合资产",
  NFTSDes:"NFTs",
  DCNFTsDes:"DC-NFT",
  DCNFTDes:"DC-NFT",
  MixedToken:"NFT/FT",
  MixedDCNFT:"DC-NFT asset",
  PrivateToken:"MSAP",
  PrivateDCNFT:"Physical futures",
  BulkToken:"FTs in circulation",
  BulkDCNFT:"Bulk asset synthesis",
  CommingSoone:"coming soon",
  packLoadingTitle:"组合资产",
  JumpWallet:"请您在钱包中批准交易",
  JumpWalletTips:"合成业务可能会发起多笔交易，请依次批准，完成合成时间预计",
  Minute:"分钟",
  ContinueSynthesize:"继续合成",
  ReceivePoints:"领取积分",
  GoBackpack:"去背包"
}

let name = 'synthesis'

export {
  name, en, zh
}