let en =  {
  PersonalCenter:"User Center",
  AssetManagement:"Assets",
  MyRecord:"Records",
  MyOrder:"Orders",
  MyDCNFT:"My DC-NFTs",
  MyContracts:"My Contracts",
  operationRecord:"Operations",
  transactionRecord:"Transfers",
  PointsRecord:"PointsRecord",
  BoughtOrder:"Purchased",
  ListedForSale:"Listed"
 }
 
 let zh = {
  PersonalCenter:"个人中心",
  AssetManagement:"资产管理",
  MyRecord:"我的记录",
  MyOrder:"我的订单",
  MyDCNFT:"我的DC-NFT",
  MyContracts:"我的合约",
  operationRecord:"操作记录",
  transactionRecord:"转移记录",
  PointsRecord:"积分记录",
  BoughtOrder:"我买到的",
  ListedForSale:"我挂售的"
 }
 
 let name = 'sidebar'
 
 export {
   name, en, zh
 }