let en =  {
  connectWallet:"Connect Wallet",
  Initializing:"Initializing",
  error:"Error connecting",
  waiting:"Loading",
  WallectConnectWarning:"As there may be network restrictions in your area, please try a wall-flipping software or proxy server first."
}

let zh = {
  connectWallet:"登录钱包",
  Initializing:"正在初始化...",
  error:"连接错误...",
  waiting:"等待登录中...",
  WallectConnectWarning:"由于您所在的地区可能存在网络限制，请先试用翻墙软件或代理服务器"
}

let name = 'connectWallet'

export {
  name, en, zh
}