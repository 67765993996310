let en =  {
  PointsReceive:"Receive Points",
  PointsReceiveDes:"Claim Points Description",
  Received:"received Points",
  ToBeClaimed:"toBeClaimed Points",
  All:"All",
  Claimable:"Claimable",
  NotExpired:"NotExpired",
  ClaimAllPoints:"ClaimAllPoints",
  Current:"Current Points",
  CastingDays:"CastingDays",
  ClaimPoints:"ClaimPoints",
  EarnPoint:"Earn Point",
  GetTime:"Get Time",
  Synthesis:"Synthesis",
  Sale:"Sale",
  Buy:"Buy"
}

let zh = {
  PointsReceive:"领取积分",
  PointsReceiveDes:"领取积分描述",
  Received:"已领取积分",
  ToBeClaimed:"待领取积分",
  All:"全部",
  Claimable:"可领取积分",
  NotExpired:"未到期积分",
  ClaimAllPoints:"领取全部积分",
  Current:"当前积分",
  CastingDays:"铸造天数",
  ClaimPoints:"领取积分",
  EarnPoint:"获取积分",
  GetTime:"获取时间",
  Synthesis:"组合",
  Sale:"挂售",
  Buy:"购买"
}

let name = 'receive'

export {
  name, en, zh
}