let en =  {
  title:"sell collection",
  seriesName:"series name",
  saleMarket:"saleMarket",
  type:"sale type",
  typeOne:"priced to sell",
  typeTwo:"auction",


  buyNow:"buyNow",
  advice:"It is recommended that you set this price to a higher price, and other users can directly pay this price for transactions",
  reservePrice:"reserve price",


  saleAmount:"Sale Price",
  basePrice:"Base price",

  duration:"Duration",
  
  startDate:"Start date",
  endDate:"End date",
  
  reserve:"reserve",
  serviceFee:"Service fee",
  earnPoints:"Earn points",
  transactionComplete:"transactionComplete",
  netIncome:"Net income",
  cancelSale:"Cancel sale",
  sellCollection:"Sell collection",
  SaleSuccess:"Sale success",
  SaleSuccessTips:"Your DC-NFT has been listed for sale on the DC-NFT market",
  SaleError:"Sale Error",
  SaleErrorTips:"Your DC-NFT listing failed",
  totalPrice:"Total price",
  Sale:"Sale",
  SaleJumpWallet:"Will jump to your wallet",
  SaleTips:"Please approve, review and confirm your sale item in your wallet",
  Reselect:"Reselect",
  SaleLoadingTitle:"The collection is being put on the shelves",
  Your:"Your",
  SaleLoadingTips:"assets are being processed and put on the shelves. They will be confirmed on the blockchain soon. The processing time is estimated to be",
  Minute:"minute",
  TransactionHash:"Transaction hash:",
  GetPoints:"Get points",
  TransactionComplete:"(Transaction Complete)"
}

let zh = {
  title:"出售藏品",
  seriesName:"系列名称",
  saleMarket:"发售市场",
  type:"出售方式",
  typeOne:"定价出售",
  typeTwo:"拍卖",

  buyNow:"一口价",
  advice:"建议您将此价格设置为较高的价格，其他用户可直接支付此价格交易",
  reservePrice:"底价金额",


  saleAmount:"售价",
  basePrice:"底价金额",

  duration:"持续时间",
  
  startDate:"开始时间",
  endDate:"结束时间",
  
  reserve:"底价",
  serviceFee:"平台服务费",
  earnPoints:"获取积分",
  transactionComplete:"交易完成",
  netIncome:"净收益",
  cancelSale:"取消出售",
  sellCollection:"出售藏品",
  SaleSuccess:"出售成功",
  SaleSuccessTips:"您的DC-NFT已挂售到DCNFT市场",
  SaleError:"出售失败",
  SaleErrorTips:"您的DCNFT挂售失败",
  totalPrice:"总价格",
  Sale:"出售",
  SaleJumpWallet:"将跳转到您的钱包",
  SaleTips:"请在钱包中批准，查看并确认您的出售项目",
  Reselect:"重新选择",
  SaleLoadingTitle:"藏品正在上架中",
  Your:"您的",
  SaleLoadingTips:"资产正在处理上架中，很快会在区块链上得到确认，处理时间预计",
  Minute:"分钟",
  TransactionHash:"交易哈希：",
  GetPoints:"获取积分",
  TransactionComplete:"（交易完成）"
}

let name = 'Sale'

export {
  name, en, zh
}