let en =  {
  copyright:"@2023 MSAP.app All Rights Reserved",
  privacy:"Privacy Policy",
  terms:"Terms of Use",
  Title:"Title",
  Content:"Content",
  Chinese:"Chinese",
  English:"English"
}

let zh = {
  copyright:"@2023 MSAP.app All Rights Reserved",
  privacy:"隐私政策",
  terms:"使用条款",
  Title:"标题",
  Content:"内容",
  Chinese:"中文",
  English:"英文"
}

let name = 'about'

export {
  name, en, zh
}