// import { wallet } from '@/js/web3/web3';
import axios from 'axios'
import {apiServerBaseUrl} from "@/js/network/constant";
import store from '@/store'
import { GetLang, SetLang } from '@/js/i18n/localStorage'
import { computed } from 'vue';
const fromAddress = computed(()=>store.state.wallet.address);

interface Common {
  address: string;
  lang: string;
  signature?: any;
}

async function createCommon(isSignature? :boolean,data? :any, address? :string) {
  const common : Common = {
    address: address?address:fromAddress.value,
    lang: GetLang("LANG")
  };
  if (isSignature){
    let tx_sig = await (window as any).web3.eth.personal.sign(JSON.stringify(data),fromAddress.value).catch(() => {
      return null
    });
    common.signature = {
      raw_string: JSON.stringify(data), 
      signature: tx_sig.substring(2), 
      signer: fromAddress.value
    };
  }
  return common;
}

function createFileRequest(file: any, req_data: any): FormData {
  const requestObj = new FormData();
  for (let i in file) {
    requestObj.append(i, file[i]);
  }
  requestObj.append('req_data', JSON.stringify(req_data));
  return requestObj;
}

async function parseResponse(res) {
  if(res.err) {
    let data = {}
    if(res.err.response) {
      data = {
        status: res.err.response.status,
        data: res.err.response.data
      }
    }
    return new Promise(r=>{
      r({data: data, err: res.err})
    })
  } else {
    return new Promise(r=>{
      r({data: res.data, err: null})
    })
  }
}

async function doGet(uri,params?,type? ) {
  let ChainID = await (window as any).web3.eth.getChainId();
  let headers:any = {}
  let res = await axios.get(`${apiServerBaseUrl}${uri}`,{
    params,
    headers
  }).catch(err=>{
      return {err: err}
    })

  return parseResponse(res)
}

async function doPost(uri :string, data? :any, isSignature? :boolean, file? :any ,address? :string ) :Promise<unknown>{
  let headers :any;
  let requestObj :any;
  if(file){
    const req_data = {
      common: await createCommon(isSignature,data,address),
      data:data?data:{},
    }
    requestObj = createFileRequest(file, req_data);
  }else{
    requestObj = {
      common: await createCommon(isSignature,data,address),
      data:data?data:{},
    }
  }
  let res = await axios.post(`${apiServerBaseUrl}${uri}`, requestObj, {
    headers: file ? { 'content-type': 'multipart/form-data' } : headers
  }).catch(err=>{
    return {err: err}
  })

  return parseResponse(res)
}

export default { 
  doGet,
  doPost
}