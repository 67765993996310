let en =  {
  Title:"Get Dividends",
  Describe:"The POINT destruction dividend pool, which runs in phases, has a 30-day period (duration is configurable). On the first day at the beginning of each period, the user destroys points to declare his share in the pool through the pool's interface and receives a share certificate NFT.",
  Button:"Try Now",
}

let zh = {
  Title:"获取分红",
  Describe:"POINT冻结分红池分阶段运行，有 30 天的期限（持续时间可配置）。第一天在每个时期开始时，用户销毁点数以声明他的通过池的接口共享池并接收共享证书NFT。",
  Button:"立即体验"
}

let name = 'dividends'

export {
  name, en, zh
}