let en =  {
  CollectionDetails:"Collection details",
  CollectionDes:"xxxxxx",
  CollectionImage:"Collection image",
  Back:"Back",
  SaveSeries:"Save series",
  Cancel:"Cancel",
  CreateSeries:"Create Series",
  Tips:"Pay creators capped at 5% of revenue for each DC-NFT sale",
  RecommendedSize:"recommended size"
}

let zh = {
  CollectionDetails:"系列详情",
  CollectionDes:"xxxxxx",
  CollectionImage:"系列图片",
  Back:"返回",
  SaveSeries:"保存系列",
  Cancel:"取消",
  CreateSeries:"创建系列",
  Tips:"每次 DC-NFT 销售向创作者支付的收入上限为 5%",
  RecommendedSize:"推荐尺寸"
}

let name = 'edit'

export {
  name, en, zh
}