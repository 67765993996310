let en =  {
  QuestionOne:"What is MSAP?",
  QuestionTwo:"Why do crypto assets need to be combined?",
  QuestionThree:"How does MSAP help users benefit?",
  QuestionOneAnswer:"MSAP is a crypto asset synthesis platform that provides users with a new way of asset management, transfer and trading. Users can flexibly combine any original digital assets in their hands into an NFT, or decompose the synthesized NFT to get the original assets.",
  QuestionTwoAnswer:"The current NFTs and the entire DeFi market lack liquidity and asset diversity, resulting in stagnation of innovation, reduced trading activity, and devaluation of crypto assets held by users. MASP creates unlimited new assets and liquidity by allowing crypto assets to be freely synthesized, creating new trading markets, and activating the potential value of crypto assets.",
  QuestionThreeAnswer:"MSAP creates diversified liquidity, new trading markets and structures by allowing crypto assets to be synthesized, boosting asset trading efficiency with lower slippage, and helping users discover and seize more trading opportunities.",
  ViewMoreHelp:"More FAQ"
}

let zh = {
  QuestionOne:"MSAP是什么？",
  QuestionTwo:"为什么数字资产需要组合？",
  QuestionThree:"MSAP能为用户带来哪些收益？",
  QuestionOneAnswer:"MSAP是数字资产资产合成平台，为用户提供全新的资产管理、转移和交易方式。用户可以将手中任意的原始数字资产灵活的组合成一个NFT，也可以将组合出的NFT拆分得到原始资产。",
  QuestionTwoAnswer:"目前NFT以及整个DeFi市场的流动性和资产多样性不足，导致赛道僵化，交易活跃度降低，用户持有的数字资产贬值。MASP通过数字资产自由合成的方式，创造无限的新资产和流动性，创建新的交易市场，激活数字资产的潜在价值 。",
  QuestionThreeAnswer:"MSAP通过资产可组合性，创造出多样化的流动性，新的交易市场和交易结构，支撑用户通过更低的滑点，指数级的提高资产交易效率，帮助用户发现和抓住更多的交易机会。",
  ViewMoreHelp:"查看更多帮助"
}

let name = 'answer'

export {
  name, en, zh
}