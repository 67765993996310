import {i18nBuilder} from "../tools";

let i18n = i18nBuilder([
  require('./private'),
  require('./block'),
  require('./nftMarket'),
  require('./nftPopup/sale'),
  require('./nftPopup/cancelSell'),
  require('./nftPopup/buy')
])

export default {
  i18n
}

