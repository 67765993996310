let en =   {
  title:"分红介绍",
  des:"自由组合您的资产，打开新兴的市场策略，提高数字资产的价值和稀缺性；组合后还可一键拆分，灵活管理资产。",
  btn:"获取积分",

  //tab
  integralTab:"积分质押分红",
  DCNFTTab:"投资质押分红",
  stakeTab:"MSAP质押分红",




  //积分
  integralPoolDesTitle:" 积分质押 ",
  integralPoolDes:"赚取MSAP（可在 Uniswap 出售 MSAP 获得 ETH）",
  integralRuleTitle:"规则介绍",
  integralRuleDes:"质押MSAP代币即可获得市场手续费奖励。50%的市场手续费将持续分配给投资质押分红池中，将会在每天上午8点持续发放奖励。你获得的市场手续费奖励将在你每次质押或提取MSAP代币时自动提取到你的钱包。",
  
  
  
  integralTitle:"质押 Token",
  integralSubtitle:"背包中的 Token",
  maxNum:"最大",
  integralNum:"800积分",//placeholder
  integralBtn:"质押",
  integralHold:"持有 Token 数量",
  integralDestroy:"质押 Token 数量",



  integralBtnGetTitle:"领取收益",
  integralBtnGetSubtitle:"分得市场手续费",
  integralBtnGet:"领取",
  integralGetAward:"已领取收益",


 
  


  //锁定分红

  lockingPoolDesTitle:" 质押 DC NFT ",
  lockingPoolDes:"赚取MSAP市场手续费，包含 ETH 、USDT等",
  lockingRuleTitle:"规则介绍",
  lockingRuleDes:"质押MSAP代币即可获得市场手续费奖励。50%的市场手续费将持续分配给投资质押分红池中，将会在每天上午8点持续发放奖励。你获得的市场手续费奖励将在你每次质押或提取MSAP代币时自动提取到你的钱包。",
  
  lockingTotalTitle:"锁定累计分红",//累积
  lockingTotalTips:"锁定该质押池本期活动开始至今累计的服务费用",//累积
  

  lockingTitle:"质押 Token",
  lockingSubtitle:"背包中的 Token",
  lockingMaxNum:"最大",
  lockingNum:"800积分",//placeholder
  lockingBtn:"质押",
  lockingHold:"持有 Token 数量",
  lockingDestroy:"质押 Token 数量",


  lockingBtnGetTitle:"领取收益",
  lockingBtnGetSubtitle:"分得市场手续费",
  lockingBtnGet:"领取",
  lockingGetAward:"已领取收益",




  lockingReturnTitle:"取回 Token",
  lockingReturnSubtitle:"选择取回的 Token 数量",
  lockingReturnBtn:"取回",
  lockingReturnDes:"取回Token时会将当前已有收益一并存到您的钱包",
  lockingReturnToken:"取回 Token 数量",







  //质押分红
  stakePoolDesTitle:" 质押 DC NFT ",
  stakePoolDes:"赚取MSAP市场手续费，包含 ETH 、USDT等",
  stakeRuleTitle:"规则介绍",
  stakeRuleDes:"质押MSAP代币即可获得市场手续费奖励。50%的市场手续费将持续分配给投资质押分红池中，将会在每天上午8点持续发放奖励。你获得的市场手续费奖励将在你每次质押或提取MSAP代币时自动提取到你的钱包。",
  
  stakeTotalTitle:"质押累计分红",//累积
  stakeTotalTips:"质押该质押池本期活动开始至今累计的服务费用",//累积
  stakePoints:"质押点数比(%)",
  stakePointsTips:"质押点数比(%)",
  stakeToken:"质押Token数量",
  stakeTokenTips:"质押Token数量",
  totalPrice:"总价",
  
  

  stakeTitle:"质押 MSAP",
  stakeSubtitle:"背包中的 MSAP",
  stakeMaxNum:"最大",
  stakeNum:"800积分",//placeholder
  stakeBtn:"质押",
  stakeHold:"持有数量",
  stakeDestroy:"质押数量",


  stakeBtnGetTitle:"领取收益",
  stakeBtnGetSubtitle:"分得市场手续费",
  stakeBtnGet:"领取",
  stakeGetAward:"已领取收益",




  stakeReturnTitle:"取回 MSAP",
  stakeReturnSubtitle:"选择取回的 MSAP 数量",
  stakeReturnBtn:"取回",
  stakeReturnDes:"取回Token时会将当前已有收益一并存到您的钱包",
  stakeReturnToken:"取回 Token 数量",
}

let zh = {
  title:"分红介绍",
  des:"自由组合您的资产，打开新兴的市场策略，提高数字资产的价值和稀缺性；组合后还可一键拆分，灵活管理资产。",
  btn:"获取积分",

  //tab
  integralTab:"积分质押分红",
  DCNFTTab:"投资质押分红",
  stakeTab:"MSAP质押分红",




  //积分
  integralPoolDesTitle:" 积分质押 ",
  integralPoolDes:"赚取MSAP（可在 Uniswap 出售 MSAP 获得 ETH）",
  integralRuleTitle:"规则介绍",
  integralRuleDes:"质押MSAP代币即可获得市场手续费奖励。50%的市场手续费将持续分配给投资质押分红池中，将会在每天上午8点持续发放奖励。你获得的市场手续费奖励将在你每次质押或提取MSAP代币时自动提取到你的钱包。",
  
  
  
  integralTitle:"质押 Token",
  integralSubtitle:"背包中的 Token",
  maxNum:"最大",
  integralNum:"800积分",//placeholder
  integralBtn:"质押",
  integralHold:"持有 Token 数量",
  integralDestroy:"质押 Token 数量",



  integralBtnGetTitle:"领取收益",
  integralBtnGetSubtitle:"分得市场手续费",
  integralBtnGet:"领取",
  integralGetAward:"已领取收益",


 
  


  //锁定分红

  lockingPoolDesTitle:" 质押 DC NFT ",
  lockingPoolDes:"赚取MSAP市场手续费，包含 ETH 、USDT等",
  lockingRuleTitle:"规则介绍",
  lockingRuleDes:"质押MSAP代币即可获得市场手续费奖励。50%的市场手续费将持续分配给投资质押分红池中，将会在每天上午8点持续发放奖励。你获得的市场手续费奖励将在你每次质押或提取MSAP代币时自动提取到你的钱包。",
  
  lockingTotalTitle:"锁定累计分红",//累积
  lockingTotalTips:"锁定该质押池本期活动开始至今累计的服务费用",//累积
  

  lockingTitle:"质押 Token",
  lockingSubtitle:"背包中的 Token",
  lockingMaxNum:"最大",
  lockingNum:"800积分",//placeholder
  lockingBtn:"质押",
  lockingHold:"持有 Token 数量",
  lockingDestroy:"质押 Token 数量",


  lockingBtnGetTitle:"领取收益",
  lockingBtnGetSubtitle:"分得市场手续费",
  lockingBtnGet:"领取",
  lockingGetAward:"已领取收益",




  lockingReturnTitle:"取回 Token",
  lockingReturnSubtitle:"选择取回的 Token 数量",
  lockingReturnBtn:"取回",
  lockingReturnDes:"取回Token时会将当前已有收益一并存到您的钱包",
  lockingReturnToken:"取回 Token 数量",







  //质押分红
  stakePoolDesTitle:" 质押 DC NFT ",
  stakePoolDes:"赚取MSAP市场手续费，包含 ETH 、USDT等",
  stakeRuleTitle:"规则介绍",
  stakeRuleDes:"质押MSAP代币即可获得市场手续费奖励。50%的市场手续费将持续分配给投资质押分红池中，将会在每天上午8点持续发放奖励。你获得的市场手续费奖励将在你每次质押或提取MSAP代币时自动提取到你的钱包。",
  
  stakeTotalTitle:"质押累计分红",//累积
  stakeTotalTips:"质押该质押池本期活动开始至今累计的服务费用",//累积
  stakePoints:"质押点数比(%)",
  stakePointsTips:"质押点数比(%)",
  stakeToken:"质押Token数量",
  stakeTokenTips:"质押Token数量",
  totalPrice:"总价",
  
  

  stakeTitle:"质押 MSAP",
  stakeSubtitle:"背包中的 MSAP",
  stakeMaxNum:"最大",
  stakeNum:"800积分",//placeholder
  stakeBtn:"质押",
  stakeHold:"持有数量",
  stakeDestroy:"质押数量",


  stakeBtnGetTitle:"领取收益",
  stakeBtnGetSubtitle:"分得市场手续费",
  stakeBtnGet:"领取",
  stakeGetAward:"已领取收益",




  stakeReturnTitle:"取回 MSAP",
  stakeReturnSubtitle:"选择取回的 MSAP 数量",
  stakeReturnBtn:"取回",
  stakeReturnDes:"取回Token时会将当前已有收益一并存到您的钱包",
  stakeReturnToken:"取回 Token 数量",
}

let name = 'earn'

export {
  name, en, zh
}