let en =  {
  Title:"Portfolio Assets Widget",
  Burn:"Burn",
  BurnDes:"After a random combination generates a new NFT, it can be investigated at any time, and the combined assets are still on your address",
  Synthesis:"Synthesis",
  SynthesisDes:"Different assets can be combined arbitrarily to form a new NFT",
  Button:"Try Now"
}

let zh = {
  Title:"组合资产小工具",
  Burn:"拆分",
  BurnDes:"随机组合生成新的NFT后，可随时进行调查，随后组合的资产仍在您的地址上",
  Synthesis:"组合",
  SynthesisDes:"可将不同的资产进行任意组合，形成一个新的NFT",
  Button:"立即体验"
}

let name = 'portfolioAssets'

export {
  name, en, zh
}