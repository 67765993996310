import httpServer from '../httpServer'

//Query user information
export const getUsers =()=>{
  return httpServer.doPost(`/user/summary`)
}

export const getRecords =(info)=>{
  return httpServer.doPost(`/user/operation_records`,info)
} 


export const gethotNftList =()=>{
  return httpServer.doPost('/misc/hot_nft_list')
} 


export const getTransferRecords =(params)=>{
  return httpServer.doPost(`/user/transfer_records`,params)
} 


export const uploadImg =(params)=>{
    let headers ={
    'Content-Type':'multipart/form-data'
  }
  return httpServer.doPost(`/user/profile/edit`,params,false,headers)
} 


export const getNftList =(params)=>{
  return httpServer.doPost(`/user/dc_nft_list`,params)
} 

export const getHomePageNFT = (params, address)=>{
  return httpServer.doPost(`/user/dc_nft_list`,params,false,null,address)
}

export const getSaleOrder = (params)=>{
  return httpServer.doPost(`/user/dc_nft_list`,params)
}

export const getCompletedOrder = (params)=>{
  return httpServer.doPost(`/market/nft/completed/orders`,params)
}

export const getCancelledOrder = (params)=>{
  return httpServer.doPost(`/market/nft/cancelled/orders`,params)
}

export const uncompletedOrders = (params)=>{
  return httpServer.doPost(`/market/nft/long/term/uncompleted/orders`,params)
}

export const getUserPoints = (params)=>{
  return httpServer.doPost(`/user/reward/point/history`,params)
}













export const getDcNftInfo =(chain: string | string[], contract: string | string[], tokenid: string | string[] |number)=>{
  return httpServer.doGet(`/dcnft/${chain}/${contract}/${tokenid}`)
}

export const getNftListByAddress =(chain: number|string, address: string, size: string|number, cursor: string)=>{
  return httpServer.doGet(`/list/nfts/${chain}/${address}?limit=${size}&cursor=${cursor}`)
}

export const getFtListByAddres =(chain: number|string, address: string, page: string|number, size: string|number)=>{
  return httpServer.doGet(`/list/fts/${chain}/${address}?page=${page}&limit=${size}`)
}

export const getDcNftsByAddress =(chain: number|string, address: string, page: string|number, size: string|number)=>{
  return httpServer.doGet(`/list/dcnfts/${chain}/${address}?page=${page}&limit=${size}`)
}

export const getAssetsOfDcNft =(chain: string|string[], contract: string|string[], tokenid: string|string[]|number)=>{
  return httpServer.doGet(`/dcnft/assets/${chain}/${contract}/${tokenid}`)
}







