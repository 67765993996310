let en =  {
  Title:"Portfolio Assets Acquisition Points Rules",
  RuleLevelOne:"Legendary assets, each successful combination will get 100 points",
  RuleLevelTwo:"Epic assets, each successful combination will get 50 points",
  RuleLevelThree:"precious assets, each successful combination will get 10 points",
  RuleLevelFour:"rare assets, each successful combination will get 5 points",
  RuleLevelFive:"Ordinary assets, each successful combination will get 1 point",
  RuleLevelOneFormula:"the combination formula is: A+B+C+D+Z",
  RuleLevelTwoFormula:"the combination formula is: A+B+C+D+E",
  RuleLevelThreeFormula:"the combination formula is: A+B+C+D",
  RuleLevelFourFormula:"the combination formula is: A+B+C",
  RuleLevelFiveFormula:"the combination formula is: A+B",
  Level:"Level",
  Ranking:"Ranking",
  Designation:"Designation",
  SynthesizeRequired:"Synthesize required",
  Synthesizeresult:"Synthesize result",
  EarnPoints:"Earn points",
  LevelOne:"Level A",
  LevelTwo:"Level B",
  LevelThree:"Level C",
  LevelFour:"Level D",
  LevelFive:"Level E",
  LevelOneName:"Legendary Asset",
  LevelTwoName:"Epic Assets",
  LevelThreeName:"Precious Asset",
  LevelFourName:"Rare Asset",
  LevelFiveName:"Ordinary Asset",
  GoFactory:"Go Factory",
  GetPointsFormula:"Get points formula",
  Formula:"可获取积分=默认积分+（A+B+C+D+E）× 组合天数对应的比例",
  DefaultPoints:"default points",
  DefaultPointsDes:"只要资产组合完成就可获取积分，以鼓励用户的合成行为，默认积分为1；",
  DefaultPointFormula:"A+B+C+D+E",
  DefaultPointFormulaDes:"可以看做为基础积分，没组合一个DC资产获得的“基础积分”就是组合DC资产时用到的所有NFT对应等级积分之和,具体信息查看下图:",
  ScaleTitle:"组合天数对应比例",
  ScaleTitleDes:"比例可参考下图，天数按照组合的时间起计算，24小时后天数为1天，48小时后天数为2天，以此类推",
  Days:"天数",
  CorrespondingRatio:"对应比例",
  Example:"例如",
  ExampleDes:"小新用3个NFT组合成一个DC资产，3个NFT的等级为（A+B+B），组合成功5天后拆分，那么小新可获得的",
  ExampleDesTips:"基础积分=10+8+8=26，默认积分为1,",
  ExampleDesBase:"当前距离组合成功的时间/24小时，6≥天数≥5时，",
  ExampleDesTipsBold:"天数为5，对应比例为：32%，",
  ExampleDescnContent:" 小新当前可领取的积分=1+（10+8+8）× 32%=9.32",
  SynthesizeAssets:"Synthesize Assets"
}

let zh = {
  Title:"组合资产获取积分规则",
  RuleLevelOne:"传说资产，组合成功每个获得100积分",
  RuleLevelTwo:"史诗资产，组合成功每个获得50积分",
  RuleLevelThree:"珍贵资产，组合成功每个获得10积分",
  RuleLevelFour:"稀有资产，组合成功每个获得5积分",
  RuleLevelFive:"普通资产，组合成功每个获得1积分",
  RuleLevelOneFormula:"组合公式为：A+B+C+D+Z",
  RuleLevelTwoFormula:"组合公式为：A+B+C+D+E",
  RuleLevelThreeFormula:"组合公式为：A+B+C+D",
  RuleLevelFourFormula:"组合公式为：A+B+C",
  RuleLevelFiveFormula:"组合公式为：A+B",
  Level:"等级",
  Ranking:"对应名次",
  Designation:"称号",
  CombinationRequired:"组合所需",
  Synthesizeresult:"组合结果",
  EarnPoints:"获取积分",
  LevelOne:"A级",
  LevelTwo:"B级",
  LevelThree:"C级",
  LevelFour:"D级",
  LevelFive:"E级",
  LevelOneName:"传说资产",
  LevelTwoName:"史诗资产",
  LevelThreeName:"珍贵资产",
  LevelFourName:"稀有资产",
  LevelFiveName:"普通资产",
  GoFactory:"前往工厂",
  GetPointsFormula:"获取积分公式",
  Formula:"可获取积分=默认积分+（A+B+C+D+E）× 组合天数对应的比例",
  DefaultPoints:"默认积分",
  DefaultPointsDes:"只要资产组合完成就可获取积分，以鼓励用户的合成行为，默认积分为1；",
  DefaultPointFormula:"A+B+C+D+E",
  DefaultPointFormulaDes:"可以看做为基础积分，没组合一个DC资产获得的“基础积分”就是组合DC资产时用到的所有NFT对应等级积分之和,具体信息查看下图:",
  ScaleTitle:"组合天数对应比例",
  ScaleTitleDes:"比例可参考下图，天数按照组合的时间起计算，24小时后天数为1天，48小时后天数为2天，以此类推",
  Days:"天数",
  CorrespondingRatio:"对应比例",
  Example:"例如",
  ExampleDes:"小新用3个NFT组合成一个DC资产，3个NFT的等级为（A+B+B），组合成功5天后拆分，那么小新可获得的",
  ExampleDesTips:"基础积分=10+8+8=26，默认积分为1,",
  ExampleDesBase:"当前距离组合成功的时间/24小时，6≥天数≥5时，",
  ExampleDesTipsBold:"天数为5，对应比例为：32%，",
  ExampleDescnContent:" 小新当前可领取的积分=1+（10+8+8）× 32%=9.32",
  SynthesizeAssets:"组合资产"
}

let name = 'ruleModal'

export {
  name, en, zh
}