let en =  {
  CreateExplore:"Explore new crypto assets and markets",
  Title:"",
  Describe:"Combine your FT and NFT assets flexibly, synthesize new crypto assets.",
  Describeline:"Create new markets with MSAP, together!",
  Create:"Minted",
  Users:"Users",
  Business:"Assets Synthesized",
  PointsRecord:"Points Record",
  ViewDetails:"View Details",
  PointTitle:"Point reward event",
  PointDescribe:"Join our upcoming public beta event and earn platform points. Points are an important part of our ecosystem. We will open a points lock-up mining pool, allowing event participants to mine MSAP (a crypto asset) through points.",
  SynthesizeNow:"Synthesize Now",
  CommSoon:"Coming Soon",
  SynthesizedAssets:"Been Synthesized"
}

let zh = {
  CreateExplore:"创造, 探索新的数字资产和市场",
  Title:"",
  Describe:"灵活组合您的FT和NFT资产，合成新的数字资产，",
  Describeline:"与我们一起共同探索新的数字资产市场！",
  Create:"DC-NFT Minted",
  Users:"Users",
  Business:"资产被合成",
  PointsRecord:"积分记录",
  ViewDetails:"查看详情",
  PointTitle:"积分奖励池",
  PointDescribe:"POINT冻结分红池分阶段运行，有 30 天的期限（持续时间可配置）。第一天在每个时期开始时，用户销毁点数以声明他的通过池的接口共享池并接收共享证书NFT。",
  SynthesizeNow:"去合成",
  CommSoon:"敬请期待",
  SynthesizedAssets:"Been Synthesized"
}

let name = 'home'

export {
  name, en, zh
}