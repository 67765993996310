let en =  {
  NFTMarket:"DC-NFT Market",
  DCNFTMarket:"DCNFT Market",
  NFTMarketDes:"A royalty-free NFT trading market. Through the form of DC-NFT, efficiently sell, buy, auction or bid for any combination of NFTs.",
  ComingSoon:"Coming Soon",
  Sale:"Sale",
  Split:"Split",
  CurrentPrice:"Current Price",
  Destroyed:"Destroyed",
  TradingNews:"Trading News",
  Attributes:"Attributes",
  Info:"Info",
  ExpandAll:"Expand All",
  CollapseAll:"Collapse All",
  FloorPrice:"Floor price",
  CreationDate:"creation date",
  Creator:"creator",
  Items:"Items",
  Chain:"Chain",
  SeeMore:"See more",
  SeeLess:"See less",
  TotalVolume:"TotalVolume",
  Owner:"Owner",
  AvailablePurchase:"available for purchase",
  Price:"Price",
  To:"To",
  Max:"Max",
  Min:"Min",
  Apply:"Apply",
  Filters:"Filters",
  Done:"Search",
  ClearAll:"Clear All",
  PriceLowToHigh:"Price: Low to High",
  PriceHighToLow:"Price: High to Low",
  RecentlyListed:"Recently listed",
  EndingSoon:"Ending Soon",
  BuyNow:"Buy Now",
  CancelSale:"Cancel Sale",
  FastPacking:"Fast Synthesize",
  SarchPlaceholder:"Please enter the DC-NFT name to search",
  myDCNFTSarchPlaceholder:"Please enter the asset name",
  OwnedBy:"Owned by",
  Traits:"Traits",
  Details:"Details",
  Event:"Event",
  From:"From",
  Date:"Date",
  Transfer:"Transfer",
  Offers:"Offers",
  CurrentHighestBid:"Current Highest Bid",
  Count:"Count",
  FloorDifference:"Floor Difference",
  Expiration:"Expiration",
  By:"By",
  Created:"Created",
  Owners:"Owners",
  SeriesSearchPlaceholder:"Enter ID, name search",
  Collection:"Collection",
  ContractAddress:"Contract Address",
  DCNFTID:"DC-NFT ID",
  TokenStandard:"Token Standard",
  Blockchain:"Blockchain",
  Royalties:"Royalties",
  BuyTitle:"Approval to Buy",
  GoToYourWallet:"Go to your wallet",
  BuyTips:"The system will ask you to approve this purchase from your wallet.",
  CancelSela:"Cancel Sela",
  SeriesName:"Series name",
  SalePrice:"Sale price",
  CancelSelaTips:"Your DC-NFT may be viewed by multiple users!",
  CancelSelaConfirmTips:"Please confirm whether to cancel the sale",
  CancelSelaConfirmStatusTips:"Please approve the transaction in your wallet before canceling it.",
  EarlyDeal:"Early deal",
  EarlyDealConfirm:"Are you sure you want to make a deal in advance?",
  Cancel:"Cancel",
  TheCurrentHighestBidIs:"The current highest bid is",
  MakeNnOffer:"Make an offer",
  ApproveMakeOffer:"Approve Make Offer",
  MakeOffer:"Make offer",
  BestOffer:"Best offer",
  SetBestOffer:" Set to best offer",
  Duration:"Duration",
  MakeOfferTips:"Please approve the transaction in the wallet to complete the offer",
  Receive:"Receive",
  SetBestOfferTips:"Each bid must be 5% or more higher than the previous bid",
  Decomposed:"Decompose",
  hasDecomposed:"Decomposed",
  CreateTime:"Creation time",
  Synthesize:"synthesize",
  Decompose:"decompose",
  Buy:"Buy",
  NoContentYet:"No content yet",
  AllChains:"All Chains",
  Reset:"Reset",
  BuyDCNFTTips:"You cannot buy your own listing!",
  TableCurrentPrice:"Current Price",
  TableLastPrice:"Last Sale",
  TableOwner:"Owner",
  LocationChain:"Chain",
  QuickSale:"Quick Sale",
  CompletePriceRangeTips:"Please enter the complete price range!",
  PriceComparisonTips:"The minimum price must be less than the maximum!"
}

let zh = {
  NFTMarket:"DC-NFT市场",
  DCNFTMarket:"DC-NFT市场",
  NFTMarketDes:"无版税的 NFT 交易市场。 通过 DC-NFT 的形式，高效地出售、购买、拍卖或竞价 NFT 的任意组合。",
  ComingSoon:"敬请期待",
  Sale:"挂售",
  Split:"拆分",
  CurrentPrice:"当前价格",
  Destroyed:"拆分",
  TradingNews:"最近交易",
  Attributes:"属性",
  Info:"信息",
  ExpandAll:"展开全部",
  CollapseAll:"收起全部",
  FloorPrice:"地板价",
  CreationDate:"创建日期",
  Creator:"创作者",
  Items:"创作数量",
  Chain:"链",
  SeeMore:"查看更多",
  SeeLess:"收起",
  TotalVolume:"总数量",
  Owner:"所有者",
  AvailablePurchase:"可购买",
  Price:"价格",
  To:"至",
  Max:"最大",
  Min:"最小",
  Apply:"应用",
  Filters:"筛选",
  Done:"搜索",
  ClearAll:"清除全部",
  PriceLowToHigh:"价格: 从低到高",
  PriceHighToLow:"价格: 从高到低",
  RecentlyListed:"最近上新",
  EndingSoon:"即将结束",
  BuyNow:"立即购买",
  CancelSale:"取消挂售",
  FastPacking:"快速组合",
  SarchPlaceholder:"请输入DC-NFT名称进行搜索",
  myDCNFTSarchPlaceholder:"请输入资产名称",
  OwnedBy:"所有者",
  Traits:"属性",
  Details:"信息",
  Event:"事件",
  From:"来自",
  Date:"时间",
  Transfer:"转移",
  Offers:"报价",
  CurrentHighestBid:"当前最高出价",
  Count:"数量",
  FloorDifference:"地板价差异",
  Expiration:"到期时间",
  By:"创作者",
  Created:"创作日期",
  Owners:"所有者",
  SeriesSearchPlaceholder:"输入ID、名称搜索",
  Collection:"藏品",
  ContractAddress:"合约地址",
  DCNFTID:"DC-NFT ID",
  TokenStandard:"代币标准",
  Blockchain:"区块链",
  Royalties:"版税",
  BuyTitle:"授权购买",
  GoToYourWallet:"将跳转到您的钱包",
  BuyTips:"系统将要求您从钱包中批准此次购买。",
  CancelSela:"取消出售",
  SeriesName:"Series name",
  SalePrice:"出售价格",
  CancelSelaTips:"您的 DC-NFT 可能正在被多个用户查看！",
  CancelSelaConfirmTips:"请确认是否取消出售",
  CancelSelaConfirmStatusTips:"请在钱包中批准交易后可取消完成",
  EarlyDeal:"Early deal",
  EarlyDealConfirm:"Are you sure you want to make a deal in advance?",
  Cancel:"Cancel",
  TheCurrentHighestBidIs:"The current highest bid is",
  MakeNnOffer:"Make an offer",
  ApproveMakeOffer:"Approve Make Offer",
  MakeOffer:"Make offer",
  BestOffer:"Best offer",
  SetBestOffer:" Set to best offer",
  Duration:"Duration",
  MakeOfferTips:"Please approve the transaction in the wallet to complete the offer",
  Receive:"Receive",
  SetBestOfferTips:"每次出价比例都需比上一次报价高出5%或以上",
  Decomposed:"去拆分",
  hasDecomposed:"已拆分",
  CreateTime:"创建时间",
  Synthesize:"合成",
  Decompose:"拆分",
  Buy:"购买",
  NoContentYet:"暂无内容",
  AllChains:"全部链",
  Reset:"重置",
  BuyDCNFTTips:"您不能购买自己挂售的DC-NFT!",
  TableCurrentPrice:"当前价格",
  TableLastPrice:"上次成交价格",
  TableOwner:"所有者",
  LocationChain:"所在链",
  QuickSale:"快速出售",
  CompletePriceRangeTips:"请输入完整的价格区间！",
  PriceComparisonTips:"价格最小值必须小于最大值！"
}

let name = 'nftMarket'

export {
  name, en, zh
}