let en =  {
  SelectDCNFTAssets:"Select DC-NFT Assets",
  SelectAsset:"Select",
  ChooseUpTo:"",
  IncludeNFTAssets:"Included NFTs",
  NFTsTotal:" NFTs in total",
  IncludeFTAssets:"Included FTs",
  FTsTotal:" FTs in total",
  Selected:"Selected",
  NFTName :"NFT Name ",
  TokenID:"Token ID",
  LocationChain:"Chain",
  Address:"Address",
  AvailableCount:"available count",
  IssuerAddress:"Issuer Address",
  Destruction:"Decompose",
  Count:"Count",
  Describe:"Describe",
  TokenCount:"Token Count",
  AssetsCount:"Assets Count",
  Tips:"After decomposition, the underlying assets contained in the DC-NFT will return to your wallet.",
  Reselect:"Reselect",
  AssetsTotal:"Total",
  AssetsDestruction:"Assets available after destruction",
  Assets:"Assets",
  ContractAddress:"Contract Address",
  FTName:"FT Name"
}

let zh = {
  SelectDCNFTAssets:"选择DC-NFT资产",
  SelectAsset:"选择资产",
  ChooseUpTo:"",
  IncludeNFTAssets:"包含NFT资产",
  NFTsTotal:"个NFT",
  IncludeFTAssets:"包含FT资产",
  FTsTotal:"个FT",
  Selected:"已选",
  NFTName:"NFT名称",
  TokenID:"Token ID",
  LocationChain:"所在链",
  Address:"地址",
  AvailableCount:"可用数量",
  IssuerAddress:"发行方地址",
  Destruction:"拆分",
  Count:"数量",
  Describe:"描述",
  TokenCount:"Token数量",
  AssetsCount:"资产数量",
  Tips:"拆分后，DC-NFT所包含的底层资产将返回至您的钱包。",
  Reselect:"重新选择",
  AssetsTotal:"共",
  AssetsDestruction:"销毁后可获得的资产",
  Assets:"资产",
  ContractAddress:"合约地址",
  FTName:"FT名称"
}

let name = 'destroy'

export {
  name, en, zh
}