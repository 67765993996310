import {i18nBuilder} from "../../tools";

let i18n = i18nBuilder([
  require('./toolBox'),
  require('./synthesis'),
  require('./destroy'),
  require('./copyrightSynthesis'),
  require('./popUps'),
  require('./contracts'),
  require('./mixedSynthesize'),
  require('./tips')
])

let en = i18n['en']
let zh = i18n['zh']
let name = "components"

export {
  en, zh, name
}