import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './js/i18n/index'
import  VueClipboard from 'vue3-clipboard'
// window.$I18n = i18n
// window.$I18n.t("Home.Projects.noDFI"),
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import { nextTick } from 'vue'

import '@/styles/index.scss'
import 'animate.css'
import 'animate.css/animate.compat.css'
import { imageIsExist } from '@/js/common/common'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faPaperPlane, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faYoutube,faTwitter,faDiscord,faRedditAlien,faGithub ,faXTwitter} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faYoutube, faTwitter, faDiscord, faRedditAlien, faGithub, faPaperPlane, faEnvelope, faXTwitter)

console.info(`Version: ${process.env.VERSION}`);
console.info(`Build Date: ${process.env.BUILD_DATE}`);

const app = createApp(App)
app.use(router).use(store).use(i18n).use(ElementPlus).use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
}).component("font-awesome-icon", FontAwesomeIcon).mount('#app')

app.directive('real-img', async function (el, binding) {
  let imgURL = binding.value;
  if (imgURL) {
      let exist = await imageIsExist(imgURL);
      if (exist) {
        nextTick(() => {
          el.setAttribute('src', imgURL);
        })
      }else {
        el.setAttribute('src', require('@/assets/common/msap-placeholder.png'));
      }
  }
})