let en =  {
  Title:"DC-NFT Factory",
  TitleDes:"Synthesize or decompose the DC-NFTs flexibly",
  FreeForNow:"free for now",
  Describe:"A convenient and user-friendly visual application that allows you to flexibly synthesize your FT and NFT assets into DC-NFT, or retrieve the underlying assets from your DC-NFT.",
  Packaging:"Synthesize",
  Destruction:"Decompose",
  Done:"Done",
  ContinueView:"Continue View",
  SkipBoot:"Skip",
  StepOneTitle:"Synthesize & Decompose",
  StepTwoTitle:"NFT synthesise",
  StepThreeTitle:"Contract management",
  StepFourTitle:"Synthesize new DC-NFT",
  StepOneDes:"Synthesize here means combining multiple assets into one DC-NFT. Decompose means splitting the DC-NFT into original underlying assets.",
  StepTwoDes:"We have opened up the synthesis business with NFT as the underlying asset, you can flexibly combine your NFTs into a DC-NFT.",
  StepThreeDes:"Jump to your DC-NFT contract management page, where you can quickly deploy and edit DC-NFT contracts.",
  StepFourDes:"Go to the NFT synthesis operation page, where you can select the NFT assets you want to combine and synthesize them into a new DC-NFT.",
}

let zh = {
  Title:"NFT与FT的合成",
  TitleDes:"自由组合或拆分您的DC-NFT",
  FreeForNow:"免费体验",
  Describe:"自由组合您的资产，开拓新兴市场策略，提升数字资产的价值和稀缺性；还可以一键拆分组合，实现灵活的资产管理。",
  Packaging:"打包",
  Destruction:"拆分",
  Done:"完成",
  ContinueView:"继续查看",
  SkipBoot:"跳过引导",
  StepOneTitle:"合成和拆分",
  StepTwoTitle:"NFT合成",
  StepThreeTitle:"合约管理",
  StepFourTitle:"合成新的DC-NFT",
  StepOneDes:"合成是将多个资产组合成一个DC-NFT，而拆分是将 DC-NFT 拆分成原始的底层资产。",
  StepTwoDes:"我们目前开放了以NFT作为底层资产的合成业务，您可以灵活的将您手中的NFT组合为一个DC-NFT。",
  StepThreeDes:"跳转到NFT合成操作页面，在那里您可以选择您要组合的NFT资产，并合成DC-NFT。",
  StepFourDes:"跳转到NFT合成操作页面，在那里您可以选择您要组合的NFT资产，并将他们合成为一个新的DC-NFT。",
}

let name = 'toolBox'

export {
  name, en, zh
}