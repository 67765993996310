let en =  {
  MyContracts:"My Contracts",
  TitleDes:"Create, manage, and explore your DC-NFT contracts.",
  Back:"Back",
  CreateSeries:"Create Contract",
  All:"All",
  NFTContracts:"NFT Contracts",
  MixedContracts:"DC-NFT Contracts",
  BlockContracts:"Block Contracts",
  PrivateContracts:"Private Contracts",
  EditContract:"Profile Edit",
  CheckDetails:"Show Details",
  SynthesisNFT:"Synthesis NFT"
}

let zh = {
  MyContracts:"我的合约",
  TitleDes:"创建、管理、浏览您的DC-NFT 合约",
  Back:"返回",
  CreateSeries:"创建合约",
  All:"全部",
  NFTContracts:"NFT合约",
  MixedContracts:"DC-NFT资产合约",
  BlockContracts:"大宗资产合约",
  PrivateContracts:"私募资产合约",
  EditContract:"编辑合约",
  CheckDetails:"查看详情",
  SynthesisNFT:"组合NFT"
}

let name = 'mySeries'

export {
  name, en, zh
}