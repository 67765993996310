import Web3js from 'web3'

let provider = null

async function isMetaMask (){
  
  if( !(window as any).ethereum){
    console.error('Do you have multiple wallets installed?');
    return false

  }else{
    return false
  }
}

async function loadWallet(){
  if( !(window as any).ethereum){
    console.error('Do you have multiple wallets installed?');
    return null
  }else{
    (window as any).web3 = new Web3js((window as any).ethereum)
    return (window as any).web3
  }
}

async function getChainId(){
  let ChainId = await (window as any).web3.eth.getChainId()
  return (window as any).web3.utils.toHex(ChainId)
}

export default { 
  loadWallet, 
  getChainId,
  isMetaMask
};
