let en =  {
  DeployFailed:"Deploy failed",
  DeploySuccess:"Deploy success",
  NetworkTips:"Please connect to the Polygon or Avalanche network",
  Your:"Your",
  DeploySuccessTips:"contract is deployed successfully",
  DeployFailedTips:"contract failed to deploy",
  EditedSuccessfully:"edited successfully",
  EditedFailed:"edited failed",
  EditedSuccessfullyTips:"Contract edited successfully",
  EditedFailedTips:"Bad network, please try again later",
  UploadContractAvatar:"Please upload the contract avatar",
  UploadContractFeatured:"Please upload the contract featured",
  UploadContractBackground:"Please upload the contract background",
  CopySuccess:"copy success",
  UploadImagesSmaller:"Please upload images smaller than 3M",
  UploadImagesFormat:"Upload images can only be in JPG, JPEG, PNG format!",
  SynthesizeFailed:"Synthesize failed",
  SynthesizeFailedTips:"packaging failed, please try again",
  PleaseEnterName:"Please enter name",
  PleaseEnterDescription:"Please enter description",
  DecomposeFailed:"Decompose failed",
  DecomposeSuccessful:"Decompose successful",
  DecomposeFailedTips:"Your DC-NFT decomposition failed, please try again.",
  DecomposeSuccessfulTips:"The underlying assets contained in the DC-NFT have returned to your wallet.",
  ChangeChainTips:"Please switch to the chain of this asset",
  ContractToSynthesisTips:"Please switch to a network consistent with the contract first!",
  DCNFTToDecomposeTips:"Please switch to the network consistent with the asset first!",
  DeployContractSuccess:",Can be edited in personal center",
  SelectChainJump:"Select the browser to jump to"
}

let zh = {
  DeployFailed:"部署失败",
  DeploySuccess:"部署成功",
  NetworkTips:"请连接Polygon或Avalanche网络",
  Your:"您的",
  DeploySuccessTips:"合约部署成功",
  DeployFailedTips:"合约部署失败",
  EditedSuccessfully:"编辑成功",
  EditedFailed:"编辑失败",
  EditedSuccessfullyTips:"合约编辑成功",
  EditedFailedTips:"网络不佳，请稍后重试",
  UploadContractAvatar:"请上传合约头像",
  UploadContractFeatured:"请上传合约精选图",
  UploadContractBackground:"请上传合约背景图",
  CopySuccess:"复制成功",
  UploadImagesSmaller:"请上传小于3M的图片",
  UploadImagesFormat:"上传图片只能是JPG、JPEG、PNG格式！",
  SynthesizeFailed:"组合失败",
  SynthesizeFailedTips:"组合失败，请重试",
  PleaseEnterName:"请输入名称",
  PleaseEnterDescription:"请输入描述",
  DecomposeFailed:"拆分失败",
  DecomposeSuccessful:"拆分成功",
  DecomposeFailedTips:"拆分失败，请重试",
  DecomposeSuccessfulTips:"DC-NFT中包含的底层资产已返回您的钱包。",
  ChangeChainTips:"请切换到该资产的链上。",
  ContractToSynthesisTips:"请先切换与该合约一致的网络！",
  DCNFTToDecomposeTips:"请先切换与该资产一致的网络！",
  DeployContractSuccess:"，可在个人中心编辑",
  SelectChainJump:"选择要跳转的浏览器"
}

let name = 'tips'

export {
  name, en, zh
}