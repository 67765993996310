import {i18nBuilder} from "../tools";

let i18n = i18nBuilder([
  require('./record'),
  require('./detail'),
  require('./ruleModal'),
  require('./receive')
])

export default {
  i18n
}

