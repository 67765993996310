export default {
  state: {
    connectWallet: false,
    switchChainPop: false,
    connectWalletLoading: false,
    connectWalletErrLoading: false,
    isToHome:false
  },
  getters: {
    connectWallet: state => state.connectWallet,
    switchChainPop: state => state.switchChainPop,
    connectWalletLoading: state => state.connectWalletLoading,
    connectWalletErrLoading: state => state.connectWalletErrLoading,
    isToHome: state => state.isToHome
  },
  mutations: {
    updateSwitchChain(state, payload) {
      state.switchChainPop = payload
    },
    updateConnectWallet(state, payload) {
      state.connectWallet = payload
    },
    
    updateConnectWalletLoading(state, payload) {
      state.connectWalletLoading = payload
    },
    updateConnectWalletErrLoading(state, payload) {
      state.connectWalletErrLoading = payload
    },
    updateConnectWalletTohome(state, payload){
      state.isToHome = payload
    }
  },
  actions: {

  }
};
