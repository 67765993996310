let en =  {
  title:"Operation record",
  des:"此处是积分记录的介绍介绍介绍介绍介绍介绍",
  search:"Please enter a relevant event to search",
  tableOperate:"Operate",
  earnPoints:"Earn points",
  getTime:"Get time",
  tableAssets:"Assets",
  tableTime:"Age",
  emptyText:"no-data",
  chain:"Chain",
  events:"Operation",
  relevantContract:"Contract",
  txHash:"Transaction hash",
  DeployContract:"Deploy Contract",
  MintDCNFT:"Mint",
  DecomposeDCNFT:"Decompose",
  TransferDCNFT:"Transfer",
  ChangeContractOwner:"Change Contract Owner",
  ListingDCNFT:"Listing",
  CancelListingDCNFT:"Cancel listing",
  BuyDCNFT:"Buy"
}

let zh = {
  title:"操作记录",
  des:"此处是积分记录的介绍介绍介绍介绍介绍介绍",
  search:"请输入相关事件进行搜索",
  tableOperate:"操作",
  earnPoints:"获得积分",  
  getTime:"获得时间",
  tableAssets:"资产内容",
  tableTime:"时间",
  emptyText:"暂无数据",
  chain:"所在链",
  events:"操作事件",
  relevantContract:"相关合约",
  txHash:"交易哈希",
  DeployContract:"部署合约",
  MintDCNFT:"组合",
  DecomposeDCNFT:"拆分",
  TransferDCNFT:"转移",
  ChangeContractOwner:"更换合约所有者",
  ListingDCNFT:"出售",
  CancelListingDCNFT:"取消出售",
  BuyDCNFT:"购买"
}

let name = 'pointsRecord'

export {
  name, en, zh
}