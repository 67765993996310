let en =  {
  Contracts:"Contracts",
  ContractsList:"Contracts List",
  SearchPlaceholder:"Please enter contract name",
  SelectChainPlaceholder:"Select chain",
  SelectTypePlaceholder:"select Type",
  ContractName:"Contract Name",
  Chain:"chain",
  NewContracts:"New Contracts",
  DeploymentContracts:"Deployment Contracts",
  ERC721Des:"Asset description here Asset description here ",
  ERC1155Des:"Asset description here Asset description here ",
  SelectAsset:"Select Asset",
  DeployContract:"Deploy Contract",
  Name:"Name",
  Symbol:"Symbol",
  Owner:"Owner",
  ContractType:"Contract Type",
  OwnerTips:"Default display user login wallet address, can not be modified",
  Cancel:"Cancel",
  Back:"Back synthesis",
  Deploy:"Deploy",
  ContractAddress:"Contract Address",
  TotalSupply:"Total Supply",
  CollectionIncluded:"Collection included",
  Collection:"Collection",
  ContractInformation:"Basic Information",
  DeployContractDes:"Contract Description",
  MySeries:"MySeries",
  ContractDetail:"ContractDetail",
  DeploymentComplete:"deployment complete",
  Details:"Details",
  Introduce:"Contract introduce",
  Synthesize:"Synthesize",
  Save:"Save",
  EditContract:"Edit Contract",
  EditContractDes:"Edit the contract profiles in MSAP platform.",
  ContractImage:"Image Setting",
  ContractAvatar:"Avatar",
  BackgroundImage:"Background Image",
  FeaturedImage:"Featured Image",
  CreateContract:"Create Contract",
  Address:"Address",
  Created:"Created",
  Splited:"Decomposed",
  SupportFormat:"Supported formats: jpg, png, jpeg. Size 3M",
  CreateContractDesc:"Quickly create smart contracts for you on the blockchain"
}

let zh = {
  Contracts:"合约",
  ContractsList:"合约列表",
  SearchPlaceholder:"请输入合约名称",
  SelectChainPlaceholder:"选择链",
  SelectTypePlaceholder:"选择类型",
  ContractName:"合约名称",
  Chain:"链",
  NewContracts:"新合约",
  DeploymentContracts:"部署合约",
  ERC721Des:"此处是资产描述此处是资产描述此处是资产描述此处是资产描述此处是资产描述此处是资产描述此处是资产描述",
  ERC1155Des:"此处是资产描述此处是资产描述此处是资产描述此处是资产描述此处是资产描述此处是资产描述此处是资产描述",
  SelectAsset:"选择资产",
  DeployContract:"部署合约",
  Name:"名称",
  Symbol:"符号",
  Owner:"所有者",
  ContractType:"合约类型",
  OwnerTips:"默认显示用户登录钱包地址，不可修改",
  Cancel:"取消",
  Deploy:"部署",
  Back:"返回组合资产",
  ContractAddress:"合约地址",
  TotalSupply:"总供应量",
  CollectionIncluded:"包含资产",
  Collection:"藏品",
  ContractInformation:"基础信息",
  DeployContractDes:"合约描述",
  MySeries:"我的系列",
  ContractDetail:"合约详情",
  DeploymentComplete:"deploy complete",
  Details:"Details",
  Introduce:"Contract introduce",
  Synthesize:"组合",
  Save:"保存",
  EditContract:"编辑合约",
  EditContractDes:"编辑合约在MSAP平台上的信息",
  ContractImage:"图片设置",
  ContractAvatar:"头像",
  BackgroundImage:"背景图片",
  FeaturedImage:"精选图片",
  CreateContract:"创建合约",
  Address:"地址",
  Created:"已创建",
  Splited:"已拆分",
  SupportFormat:"支持格式:jpg, png, jpeg. 大小:3M",
  CreateContractDesc:"快速在区块链上为您创建智能合约"
}

let name = 'contracts'

export {
  name, en, zh
}