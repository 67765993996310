import { createStore } from 'vuex'
import nav from './modules/nav'
import menu from './modules/menu'
import wallet from './modules/wallet'
import popUps from './modules/popUps'

export default createStore({
  modules: {
    nav,
    menu,
    wallet,
    popUps,
  },
})
