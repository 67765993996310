let en =  {
  Title:"DC-NFT synthesis",
  Describe:"Combine your NFT assets arbitrarily, improve the management, use and circulation efficiency of your assets.",
  SelectAssetType:"DC-NFT Type",
  SelectNFTAssets:"Select NFT Assets",
  SelectFTAssets:"Select FT Assets",
  ChooseUpTo:"The cumulative upper limit of selected assets is 5,you have selected",
  SelectAsset:"Select Asset",
  Tips:"The current synthesis limit is 5 different token ids, exceeding the limit is not selectable.",
  Name:"DC-NFT Name",
  Contracts:"DC-NFT Contracts",
  Description:"DC-NFT Description",
  DeployContract:"Deploy New Contract",
  Packaging:"Synthesize",
  PackTips:"Confirming synthesis may initiate multiple transactions, please approve these transactions in your wallet to complete the synthesis.",
  Selected:"selected",
  Address:"Address",
  SelectedCount:"selected count",
  NFTName:"NFT Name",
  ContractAddress:"Contract Address",
  Count:"Count",
  Delete:"delete",
  TokenId:"Token Id",
  FTName:"FT Name",
  SelectAssets:"SelectAssets",
  SelectNFT:"Select NFT",
  SelectFT:"Select FT",
  NFTAssets:"NFT Assets",
  FTAssets:"FT Assets",
  Comma:",",
  CurrentChain:"Current Chain",
  point:"point"
}

let zh = {
  Title:"DC-NFT合成",
  Describe:"任意的组合您的NFT资产，提高资产的管理、使用和流通效率。",
  SelectAssetType:"DC-NFT类型",
  SelectNFTAssets:"选择NFT资产",
  SelectFTAssets:"选择FT资产",
  ChooseUpTo:"一次最多选择 5 个资产，已选资产",
  SelectAsset:"选择资产",
  Tips:"目前单次合成上限为5个不同的token id，超出不可选择",
  Name:"名字",
  Contracts:"DC-NFT 合约",
  Description:"DC-NFT 描述",
  DeployContract:"部署合约",
  Packaging:"合成",
  PackTips:"确认合成可能会发起多笔交易，请您在钱包中批准这些交易来完成合成。",
  Selected:"已选",
  Address:"地址",
  SelectedCount:"已选数量",
  NFTName:"NFT名称",
  ContractAddress:"合约地址",
  Count:"数量",
  Delete:"删除",
  TokenId:"Token Id",
  FTName:"FT名称",
  SelectAssets:"选择资产",
  SelectNFT:"选择NFT",
  SelectFT:"选择FT",
  NFTAssets:"NFT 资产",
  FTAssets:"FT 资产",
  Comma:"，",
  CurrentChain:"所在链",
  point:"积分"
}

let name = 'mixedSynthesize'

export {
  name, en, zh
}