const mySeries = [
  // { 
  //   path: '/contracts',
  //   name:'SeriesContracts',
  //   component: () => import('@/views/userCenter/mySeries/index.vue'),
  // },
  // { 
  //   path: '/contracts/create',
  //   name:'createSeries',
  //   component: () => import('@/views/userCenter/mySeries/createSeries.vue'),
  // },
  { 
    path: '/contracts/edit',
    name:'editContract',
    component: () => import('@/views/user/contracts/edit.vue'),
  },
  { 
    path: '/contracts/create',
    name:'deployContract',
    component: () => import('@/views/user/contracts/create.vue'),
  },
  {
    path:"/profile/:id",
    name:'Homepage',
    component: () => import('@/views/profile/index.vue'),
  }
]

export default mySeries

