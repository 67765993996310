const userCenter = {
  path: '/account',
  name:'account',
  component: () => import('@/views/user/index.vue'),
  children:[
    {
      path: '',
      name: 'default',
      component: () => import('@/views/user/account/index.vue')
    },
    {
      path: 'contracts',
      name: 'contracts',
      component: () => import('@/views/user/contracts/index.vue'),
    },
    {
      path: 'transaction-record',
      name: 'transactionRecord',
      component: () => import('@/views/user/transactionRecord/index.vue')
    },
    {
      path: 'operation-record',
      name: 'operationRecord',
      component: () => import('@/views/user/operationRecord/index.vue')
    },
    // {
    //   path: 'points-record',
    //   name: 'pointsRecord',
    //   component: () => import('@/views/user/pointsRecord/index.vue')
    // },
    
    {
      path: 'buy-order',
      name: 'buyOrder',
      component: () => import('@/views/user/order/buyOrder.vue')
    },
    {
      path: 'sale-order',
      name: 'saleOrder',
      component: () => import('@/views/user/order/saleOrder.vue'),
    },
  ]
}

export default userCenter