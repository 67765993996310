import {i18nBuilder} from "../../tools";

let i18n = i18nBuilder([
  require('./home'),
  require('./features'),
  require('./portfolioAssets'),
  require('./market'),
  require('./dividends'),
  require('./howWorks'),
  require('./answer')
])

let en = i18n['en']
let zh = i18n['zh']
let name = "components"

export {
  en, zh, name
}