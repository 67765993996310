import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from 'dayjs/plugin/relativeTime';
import Validator from 'wallet-validator'
import Identicon from 'identicon.js'
import DecimalMath from "decimal.js";
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/en'
import { GetLang, SetLang } from '@/js/i18n/localStorage'
import Blacklist from '@/assets/jsonFile/blacklist.json'
// import * as isIPFS from 'is-ipfs'

let asSeconds = dayjs.extend(duration);
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)


let FormatTime = function(time){
  return dayjs(time).format("YYYY-MM-DD HH:mm:ss");
}

let FormatTimelll = function (time){
  return dayjs(time).format("MMM D, YYYY h:mm A");
}

let currentTime = function(){
  return dayjs().format('YYYY/M/D');
}

let timeLater = function(num: number, format: any){
  return dayjs().add(num, format).format('YYYY/M/D');
}

let getTimeFromNow = function (timestamp: number) {
  return dayjs.unix(timestamp).fromNow();
}

let Timestamp = function(time){
  return dayjs(time).unix();
}
let DurationTime = function(time,unit){
  return dayjs.duration(time, unit).asSeconds();
}

let timezoneTime = function(time :string){
  let timestamp = dayjs.utc(time, "YYYY-MM-DD HH:mm:ss").unix()
  return dayjs.unix(timestamp).format( "YYYY-MM-DD HH:mm:ss")
}

//jschardet
let checkEncoding = function(base64Str) {
  let str = atob(base64Str.split(";base64,")[1]);
  var jschardet = require("jschardet");
  let encoding = jschardet.detect(str);
  encoding = encoding.encoding;
  if (encoding == "windows-1252") {
    encoding = "ANSI";
  }
  return encoding;
}

let group = function(array, subGroupLength) {
  let index = 0;
  let newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)));
  }
  return newArray;
}

let isValidAddress = function(address){
  if(address){
    var valid = Validator.validate(address,'ethereum');
    if(valid){
      return true;
    }else{
      return false;
    }
  }
  return false;
}

let getStringLength = (val)=> {
  let str = new String(val);
  let bytesCount = 0;
  for (let i = 0, n = str.length; i < n; i++) {
    let c = str.charCodeAt(i);
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      bytesCount += 1;
    } else {
      bytesCount += 2;
    }
  }
  return bytesCount;
}


let formatterHash = (address :string)=>{
  if (address) {
    if (getStringLength(address) > 16) {
      return (
        address.substr(0, 6) + "..." + address.substr(address.length - 4)
      );
    } else {
      return address;
    }
  }
}

let getDefaultAvatar = (address :any)=>{
  if(getStringLength(address) > 16){
    let imgData = new Identicon(address, 420).toString()
    let defaultAvatar = 'data:image/png;base64,'+imgData;
    return defaultAvatar;
  }else{
    return '';
  }
}

let toFinancialVal = (value: any)=>{
  if (typeof value !== "undefined" && !isNaN(value)) {
    DecimalMath.set({ toExpNeg: -10 });
    value = new DecimalMath(value).toString();
    if (value === "0") return value;

    let dotIndex = value.indexOf(".");
    let valueLast = "";

    // Remove the decimal point
    if (dotIndex !== -1) {
      valueLast = value.substr(dotIndex);
      value = value.substr(0, dotIndex);
    }

    let tmpVal = value.split("").reverse();
    let retStr = "";

    for (let i = 0; i < tmpVal.length; i++) {
      if ((i + 1) % 4 === 0) {
        tmpVal.splice(i, 0, ",");
      }
    }
    tmpVal.reverse();

    for (let i = 0; i < tmpVal.length; i++) {
      retStr += tmpVal[i];
    }

    let retNum = retStr + valueLast;
    let dotIndex2 = retNum.indexOf(".");
    if (dotIndex2 !== -1 && retNum.length > (dotIndex2 + 5)) {
      retNum = retNum.substr(0, dotIndex2 + 5);
    }

    return retNum;
  } else {
    return 0;
  }
}

let DecimalERC20Amount = (amount :string|number, decimals:number|string)=>{
  let ERC20Amount = new DecimalMath(amount).div(new DecimalMath(`1e${decimals}`)).toString();
  let num = Number(ERC20Amount);
  let result = Math.floor(num * 1000000) / 1000000;
  return result.toFixed(6);
}

let DecimalERCMulAmount = (amount :string|number)=>{
  let ERC20Amount = new DecimalMath(amount).mul(new DecimalMath(`1e${18}`)).toString();
  return  ERC20Amount
}

let isEqual  = (obj1: any, obj2: any)=> { 
  // 获取对象的所有属性
  var keys1 = Object.keys (obj1);
  var keys2 = Object.keys (obj2);
  // 如果属性数量不同，返回false
  if (keys1.length !== keys2.length) {
    return false;
  }
  // 遍历每个属性，比较值是否相等
  for (var key of keys1) {
    if (obj1 [key] !== obj2 [key]) {
      return false;
    }
  }
  // 如果都相等，返回true
  return true;
}  


let  getCookie = (name:any)=> {
  var acookie = document.cookie.split("; ");
  for (var i = 0; i < acookie.length; i++) {
    var arr = acookie[i].split("=");
    if (name == arr[0]) {
        if (arr.length > 1)
            return unescape(arr[1]);
        else
            return "";
    }
}
}
/**
* 参数 diffValue  时间戳
* 
*/
 
let getDate = (time)=>{	
  let lang = GetLang('en')==='en'?'en':'zh-ch'
  dayjs.locale(lang)
  const now = dayjs();
  const inputTime = dayjs(time);
  return inputTime.from(now);
}

let toIPFSGateway = (url :string) => {
  // let urlPart = url.split("://")
  // if(urlPart.length <2) {
  //   return ""
  // }

  // let cidPart = null
  // urlPart = urlPart[1].split("/")
  // for(let i=0; i<urlPart.length; i++) {
  //   if(isIPFS.cid(urlPart[i])) {
  //     cidPart = urlPart.slice(i).join('/')
  //     break
  //   }
  // }

  // if(cidPart !== null) {
  //   return `https://ipfs.io/ipfs/${cidPart}`
  // }
  return url
}

let checkBlacklist = (imageUrl)=>{
  if(imageUrl){
    const domain = new URL(imageUrl).hostname;
    if (Blacklist.includes(domain)) {
      return true;
    } else {
      return false;
    }
  }
}

/**
 * 检测图片是否存在
 * @param url
 */
let imageIsExist = function(url) {
  return new Promise((resolve) => {
      var img :any = new Image();
      img.onload = function () {
          if (this.complete == true){
              resolve(true);
              img = null;
          }
      }
      img.onerror = function () {
          resolve(false);
          img = null;
      }
      img.src = url;
  })
}
/**
 * 计算与当前时间差天数
 * @param sellDate
 */
let calculateOverdueDays = (sellDate)=>{
  let now = dayjs().tz()
  let sellDay = dayjs(sellDate)
  let diffDays = now.diff(sellDay, 'day');

  if (diffDays < 1) {
    return '1'
  } else {
    return `${diffDays}`
  }
}

function numFormat(num) {
  if(num >= 10001) {
    num = Math.round(num/1000)/10 + 'W+';
  } else if (num >= 1001) {
    num = Math.round(num/100)/10 + 'K+';
  }
  return num;
}

export {
  getDate,
  FormatTime,
  currentTime,
  FormatTimelll,
  timeLater,
  Timestamp,
  getTimeFromNow,
  DurationTime,
  checkEncoding,
  group,
  isValidAddress,
  formatterHash,
  getDefaultAvatar,
  toFinancialVal,
  DecimalERC20Amount,
  isEqual,
  DecimalERCMulAmount,getCookie,
  timezoneTime,
  toIPFSGateway,
  checkBlacklist,
  imageIsExist,
  calculateOverdueDays,
  numFormat
}