import Web3 from 'web3';
import UniversalProvider from "@walletconnect/universal-provider";
import { Web3Modal } from '@web3modal/standalone';
import { ref ,watchEffect } from 'vue';
import store from '@/store';
import { initContracts, contractAddress } from "@/js/web3/contract";

const client = ref(null);
const ethereumProvider = ref<UniversalProvider>();
const web3Provider = ref<Web3>();
const web3ModalView = ref<Web3Modal>();
const session = ref<any>();
const hasCheckedPersistedSession = ref(false)
const isInitializing = ref(false)
const PROJECT_ID = '79dcf9a01a64fa1e53eea1889d8ca762'

const createWeb3Provider = (ethereumProvider: UniversalProvider) => {
  const Provider = new Web3(ethereumProvider);
  web3Provider.value = Provider;
  (window as any).web3 = Provider;
};

const resetApp = () => {
  store.commit('updateAccountsChanged',"");
  store.commit('updateChainId',"");
  store.commit('updateChainName',"");
  store.commit('updateWalletType',"");
  session.value = undefined;
};


export const connect = async (ChainId: string) => {
  if (!ethereumProvider.value) {
    throw new ReferenceError("WalletConnect Client is not initialized.");
  }
  const _session = await ethereumProvider.value.connect({
    namespaces: {
      eip155: {
        methods: [
          "eth_sendTransaction",
          "eth_signTransaction",
          "eth_sign",
          "personal_sign",
          "eth_signTypedData"
        ],
        chains: ['eip155:11155111'],
        events: ["chainChanged", "accountsChanged"],
        rpcMap: {
          11155111:
            `https://rpc.walletconnect.com/v1/?chainId=eip155:11155111&projectId=${PROJECT_ID}`
        },
      },
    },
    optionalNamespaces:{
      eip155:{
        "chains": [
          "eip155:43114",
          "eip155:11155111",
        ],
        "methods": [
            "eth_sendTransaction",
            "personal_sign",
            "eth_signTypedData",
            "eth_signTypedData_v4",
            "eth_sign"
        ],
        "events": [
            "chainChanged",
            "accountsChanged"
        ],
        "rpcMap": {
          "11155111": `https://rpc.walletconnect.com/v1/?chainId=eip155:11155111&projectId=${PROJECT_ID}`,
        }
    }
    }
    // pairingTopic:data.pairings[0]?.topic
  });

  const _accounts = await ethereumProvider.value.enable();
  store.commit('updateAccountsChanged',_accounts[0]);
  createWeb3Provider(ethereumProvider.value)
  session.value = _session;
  store.commit('updateChainId','0x89')
  store.commit('updateChainName',`0x89`);
  (window as any).addressMap = contractAddress();
  // store.dispatch('getPointAndBalance')
  web3ModalView.value?.closeModal();
}

export const disconnect = async () => {
  if (typeof ethereumProvider.value === "undefined") {
    throw new Error("ethereumProvider is not initialized");
  }
  await ethereumProvider.value.disconnect();
  resetApp();
};

export function ClientContextProvider () {
  const createClient = async()=>{
    try {
      console.log('createClient')
      isInitializing.value = true;
      const provider = await UniversalProvider.init({
        projectId: PROJECT_ID,
        logger: 'debug',
        relayUrl: 'wss://relay.walletconnect.com',
      });
      const web3Modal = new Web3Modal({
        projectId: PROJECT_ID || "",
        walletConnectVersion: 2,
      });
      ethereumProvider.value = provider;
      client.value = provider.client;
      web3ModalView.value = web3Modal;
    }catch(err){
      throw err;
    }finally {
      isInitializing.value = false;
    }
  }

  const _subscribeToProviderEvents = async (_client: UniversalProvider) => {
    if (typeof _client === "undefined") {
      throw new Error("WalletConnect is not initialized");
    }

    _client.on("display_uri", async (uri: string) => {
      console.log("EVENT", "QR Code Modal open");
      web3ModalView.value?.openModal({ uri });
    });

    // Subscribe to session ping
    _client.on("session_ping", ({ id, topic }: { id: number; topic: string }) => {
      console.log("EVENT", "session_ping");
      console.log(id, topic);
    });

    // Subscribe to session event
    _client.on("session_event", ({ event, chainId }: { event: any; chainId: string }) => {
      console.log("EVENT", "session_event");
      console.log(event, chainId);
    });

    // Subscribe to session update
    _client.on(
      "session_update",
      ({ topic, session }: { topic: string; session: any }) => {
        console.log("EVENT", "session_updated");
        session.value = session
      },
    );

    // Subscribe to session delete
    _client.on("session_delete", ({ id, topic }: { id: number; topic: string }) => {
      console.log("EVENT", "session_deleted");
      console.log(id, topic);
      resetApp();
    });
  }

  const getPersistedSession = async () => {
    if (!ethereumProvider.value) return;
    await _checkForPersistedSession(ethereumProvider.value);
    hasCheckedPersistedSession.value = true;
  };
  const _checkForPersistedSession =  async (provider: UniversalProvider) => {
    if (typeof provider === "undefined") {
      throw new Error("WalletConnect is not initialized");
    }
    const pairings = provider.client.pairing.getAll({ active: true });
    console.log("RESTORED PAIRINGS: ",pairings);
    console.log('session====',session.value )
    if (typeof session.value !== "undefined") return;
    console.log("ethereumProvider.session===",ethereumProvider.value?.session)
    // populates (the last) existing session to state
    if (ethereumProvider.value?.session) {
      const _session = ethereumProvider.value?.session;
      console.log("RESTORED SESSION:", _session);
      await onSessionConnected(_session);
      return _session;
    }
  }

  const onSessionConnected = async (_session) => {
    if (!ethereumProvider.value) {
      throw new ReferenceError("EthereumProvider is not initialized.");
    }
    const allNamespaceAccounts = _session.namespaces.eip155.accounts;
    const chainData = allNamespaceAccounts[0].split(":");
    session.value = _session;
    store.commit('updateAccountsChanged',chainData[2]);
    store.commit('updateWalletType','WalletConnect');
    await createWeb3Provider(ethereumProvider.value);
    const caipChainId =  await web3Provider.value.eth.getChainId();
    store.commit('updateChainId',(window as any).web3.utils.toHex(caipChainId));
    store.commit('updateChainName',(window as any).web3.utils.toHex(caipChainId));
    console.log("restored caipChainId", caipChainId);
    (window as any).addressMap = await contractAddress();
    console.log( (window as any).addressMap)
  }

  watchEffect(()=>{
    if (!client.value) {
      createClient();
    }
  })

  watchEffect(()=>{
    if (ethereumProvider.value && web3ModalView.value) _subscribeToProviderEvents(ethereumProvider.value);
  })

  watchEffect(()=>{
    if (ethereumProvider.value && !hasCheckedPersistedSession.value) {
      getPersistedSession();
    }
  })
}

export async function switchWallectChain (chainId) {
  const result = await ethereumProvider.value.request(
    { method:"wallet_switchEthereumChain", params:[{chainId:chainId}] },
    `eip155:${(window as any).web3.utils.hexToNumberString(chainId)}`
  );
  (window as any).addressMap = await contractAddress();
  // store.dispatch('getPointAndBalance')
  window.location.reload();
}


