let en =  {
  myContracts:" My Contracts",
  myBackpack:"My DC-NFTs",
  operationRecord:"Operation records",
  transactionRecord:"Transfer records",
  Balance:"Balance",
  Point:"Point",
  ConnectedWallet:"Connected Wallet",
  SwitchNetwork:"Switch Network"
 }
 
 let zh = {
  myContracts:"我的合约",
  myBackpack:"我的 DC-NFTs",
  operationRecord:"操作记录",
  transactionRecord:"转移记录",
  Balance:"余额",
  Point:"积分",
  ConnectedWallet:"连接钱包",
  SwitchNetwork:"切换网络"
 }
 
 let name = 'drawer'
 
 export {
   name, en, zh
 }